import {ListingStatus} from 'constant/listing_status'
import {ConsignStatus} from 'enums'
import {get} from 'lodash'
import moment from 'moment'
import DateUtils from 'util/date_utils'

// this validation only work on single listing per Item that v1 implemented
// if multi listing on v2 to implement then this should more listingPlatform field validation status=listed
export const isProductListed = (product) => {
  // if one listing found then it is has listing record.
  // updating this should be on listing update Page
  const i = get(product, 'listings[0]')
  return !!i && i?.status !== ListingStatus.WITHDRAW_APPROVED
}

export const isProductHasListingRecord = (product) => {
  const i = get(product, 'listings[0]')
  return !!i && i?.status !== ListingStatus.WITHDRAW_APPROVED
}

export const getListingId = (product) => {
  return get(product, 'listings[0].id')
}

export const getConsignEmail = (product) => {
  return get(product, 'consign.consignor.email')
}

export const isConsigned = (product) => {
  const consign = get(product, 'consign')
  return !!consign && ![ConsignStatus.WITHDRAW_APPROVED, ConsignStatus.RETURNED, ConsignStatus.REJECTED].includes(consign?.status)
}

export const getSoldPlatformName = (product) => {
  return get(product, 'listings[0].solds[0].listingPlatform.platform.name')
}

export const getSoldPlatformDate = (product) => {
  const sold = get(product, 'listings[0].solds[0]')
  if (!sold) return

  // Use updatedAt if sold or created before January 1, 2025; otherwise, use createdAt
  const jan1 = DateUtils.formatDateWithoutTimeZone('01/01/2025')
  const created = DateUtils.formatDateWithoutTimeZone(sold.createdAt)
  const updated = DateUtils.formatDateWithoutTimeZone(sold.updatedAt)

  return created < jan1 ? updated : created
}

export const getListingStatus = (product) => {
  if (Array.isArray(product.listings) && product.listings?.length > 0) {
    return get(product, 'listings[0].status')
  }

  return get(product, 'status')
}

export const forRemove = (product) => {
  const status = getListingStatus(product)
  return status === 'for_remove' || status === 'sold' || status === 'processed'
}

export const getSold = (product) => {
  const d = get(product, 'listings[0].solds[0]')
  return d
}

export const getFee = (product) => {
  return Number(get(product, 'consign.application.fee.value', 0))
}

export const _getEstimatedPayout = (feeStructure, desiredReturn, isRound = false) => {
  const estimatedPayout = Number((1 - feeStructure / 100) * desiredReturn)
  return isRound ? Math.round(estimatedPayout) : estimatedPayout.toFixed(2)
}
