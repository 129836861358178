import axios from 'axios'
import * as Sentry from '@sentry/react'
import {map} from 'lodash'
import {useAuth0} from '@auth0/auth0-react'
import {paths} from 'constant/api'
import {createRequestOptions} from 'util/network'
import {InventoryQueryType} from 'constant/query_type'
import {useQuery, useQueryClient} from 'react-query'

const checkEndPoint = (exportType) => {
  switch (exportType) {
    case 'orders':
      return `${paths.sold}/export`
    case 'consignment':
      return `${paths.application}/export`
    case 'ledger':
      return `${paths.ledger}/export`
    case 'consign':
      return `${paths.consign}/export`
    default:
      return `${paths.product}/export-products`
  }
}

export const fetchExportData = async (endpoint, tokenGetter, filter, queries) => {
  try {
    const token = await tokenGetter()
    const queryString = map({...filter, ...queries}, (value, key) => `${key}=${encodeURIComponent(value).replace(/%2F/g, '/')}`).join('&')

    const response = await axios.get(`${endpoint}/?${queryString}`, createRequestOptions(token))

    return response?.data
  } catch (e) {
    Sentry.captureException(e)
    throw e.message
  }
}

export const useExport = (params) => {
  const {filter = {}, queries = {}, exportType = 'inventory', type = InventoryQueryType.UNLISTED} = params

  const {getAccessTokenSilently} = useAuth0()
  const queryClient = useQueryClient()

  let query = queries
  if (exportType === 'inventory') query = {...query, type, ...filter}

  const {isError, isSuccess, refetch} = useQuery(
    'inventoryExport',
    () => fetchExportData(checkEndPoint(exportType), getAccessTokenSilently, filter, query),
    {
      enabled: false,
      staleTime: Infinity,
    },
  )

  const invalidateExportQueries = () => {
    queryClient.invalidateQueries('inventoryExport')
    queryClient.resetQueries('inventoryExport')
  }

  return {
    isErrorExport: isError,
    isSuccessExport: isSuccess,
    refetchExport: refetch,
    invalidateExportQueries,
  }
}

export default useExport
