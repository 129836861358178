import React, {useRef, useEffect} from 'react'
import clx from 'classnames'
import {FormGroup, Input, FormFeedback} from 'reactstrap'
import PhoneInput from 'react-phone-input-2'

import {NumericFormat} from 'react-number-format'
import CopytLabel from './CopytLabel'
import 'react-phone-input-2/lib/style.css'

const FieldGroup = ({
  id,
  autoFocus,
  sLabel = null,
  hasError,
  errorMessage,
  help,
  decimalScale,
  ...props
}) => {
  const inputRef = useRef(null)

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }, [])

  if (sLabel?.includes('Phone Number') || id === 'phoneNumber') {
    return (
      <FormGroup {...{id}}>
        {sLabel && <CopytLabel className="label">{sLabel}</CopytLabel>}
        <PhoneInput
          specialLabel={''}
          country={props?.country === 'empty' ? undefined : props?.country || 'us'}
          inputStyle={{
            borderColor: hasError && 'red',
            paddingLeft: '48px',
            width: '100%',
          }}
          {...props}
        />
      </FormGroup>
    )
  } else if (id?.includes('numeric')) {
    return (
      <FormGroup {...{id}} className={clx(hasError && 'has-danger')}>
        {sLabel && (
          <CopytLabel props={{error: hasError}} className="label">
            {sLabel}
          </CopytLabel>
        )}
        <NumericFormat
          allowNegative={false}
          style={{
            paddingTop: '5px',
            paddingBottom: '5px',
            paddingLeft: 16,
            ...props?._numericStyle,
          }}
          className={`numeric-input-consign ${hasError ? 'numeric-input-error' : ''}`}
          decimalScale={decimalScale ?? 2}
          fixedDecimalScale={true}
          isAllowed={(values) => {
            const {formattedValue, floatValue} = values
            return formattedValue === '' || (floatValue <= 999999999.99 && floatValue >= 0.01)
          }}
          {...props}
        />
        {help && <FormFeedback valid={false}>{help}</FormFeedback>}
        {hasError && <div className="error-message">{errorMessage}</div>}
      </FormGroup>
    )
  }

  return (
    <FormGroup {...{id}} className={clx(hasError && 'has-danger')}>
      {sLabel && <CopytLabel className="label">{sLabel}</CopytLabel>}
      <Input {...props} innerRef={autoFocus && inputRef} />
      {help && <FormFeedback valid={false}>{help}</FormFeedback>}
      {hasError && <div className="error-message">{errorMessage}</div>}
    </FormGroup>
  )
}

export default FieldGroup
