import React, {useCallback, useEffect, useState} from 'react'
import {Stack} from '@mui/material'
import {Delete as DeleteIcon} from '@mui/icons-material'
import {FormGroup} from 'reactstrap' // ToDo: Replace using mui/material

import {useGlobalStore} from 'provider/global_store/hook'
import onKeyPress from 'views_v2/lib/common/onKeyPress'
import {CopytLabel, FieldGroup, SizesDropdown} from 'views_v2/lib/snippets'
import {ob} from 'variables/empty'
import mw from '../middleware'

export const DuplicateItem = ({
  defaultValue = {},
  index,
  prices = ob,
  setDuplicateSizes,
  showDelete = false,
  setDeleteItem,
  formFields,
}) => {
  const {user} = useGlobalStore()
  const [value, setValue] = useState({...{size: '', price: '', count: 1}, ...defaultValue})
  const [hasError, setHasError] = useState(false)

  useEffect(() => {
    if (
      Object.keys(defaultValue)?.length > 0 &&
      (value.size !== defaultValue.size ||
        value.count !== defaultValue.count ||
        value.price !== defaultValue.return)
    ) {
      Object.assign(defaultValue, {
        price: defaultValue?.return,
      })
      return setValue(defaultValue)
    }
  }, [defaultValue.size, defaultValue.count, defaultValue.return])

  useEffect(() => {
    // this will make sure that prices fields will auto filled
    // even modal already loaded before prices data
    if (prices && prices[value.size]) {
      setValue((s) => ({...s, price: mw.getDesiredPrice(prices[value.size], user)}))
    }
  }, [prices, value.size])

  useEffect(() => {
    setDuplicateSizes((s) => {
      s[index] = {size: value.size, count: value.count, return: value.price}
      return [...s]
    })
  }, [setDuplicateSizes, value, index, prices])

  const onChangeSize = useCallback(
    (v) => {
      setHasError(checkIfEmptyOrZero(v))
      setValue((s) => {
        if (prices[v]) {
          return {...s, size: v, price: mw.getDesiredPrice(prices[v], user)}
        } else {
          return {...s, size: v}
        }
      })
    },
    [setValue, prices],
  )

  const onChangeReturn = useCallback(
    (e) => {
      const price = e?.target?.value
      setHasError(checkIfEmptyOrZero(price))
      setValue((s) => ({...s, price: price}))
    },
    [setValue],
  )

  const onChangeCount = useCallback(
    (e) => {
      const target = e.target
      setHasError(checkIfEmptyOrZero(target.value))
      setValue((s) => ({...s, count: target.value}))
    },
    [setValue],
  )

  const checkIfEmptyOrZero = (str) => {
    return str === '' || String(str) === '0'
  }

  const onDuplicateItem = () => {
    if (
      onlySpaces(value?.size) ||
      checkIfEmptyOrZero(value?.size) ||
      checkIfEmptyOrZero(value?.return) ||
      checkIfEmptyOrZero(value?.count)
    ) {
      setHasError(true)
    } else {
      onSubmit()
    }
  }

  return (
    <Stack>
      <Stack direction="row" gap={2} alignItems="center">
        <FormGroup>
          <CopytLabel className="label">Size *</CopytLabel>
          <SizesDropdown
            {...{formFields, value}}
            editMode={true}
            handleSizeChange={onChangeSize}
            isDuplicate
          />
        </FormGroup>
        <FieldGroup
          id="numeric"
          sLabel="List Price *"
          value={value?.price}
          onChange={onChangeReturn}
          name="desiredReturn"
          maxLength={13}
          placeholder="($)"
        />
        <FieldGroup
          sLabel="Quantity *"
          name="quantity"
          type="text"
          maxLength={4}
          value={value.count}
          onChange={onChangeCount}
          onKeyPress={(e) => onKeyPress(e, 'e')}
        />
        {showDelete && (
          <DeleteIcon
            onClick={() => {
              setDeleteItem(index)
            }}
          />
        )}
      </Stack>
      {hasError && <span>Empty or Zero value is not allowed</span>}
    </Stack>
  )
}

export default DuplicateItem
