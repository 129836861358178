import React from 'react'
import {Stack} from '@mui/material'
import {Autorenew as AutorenewIcon, ContentCopy as ContentCopyIcon, Cancel as CancelIcon} from '@mui/icons-material'

import {ModalType} from 'enums'
import CustomButton from './CustomButton'

const ProcessedItemBtn = ({tabPanel, markAsProcessed, setModalType, isLoading}) => {
  return (
    <Stack direction="row" gap={1} justifyContent="flex-end">
      <CustomButton
        icon={<CancelIcon />}
        disabled={isLoading}
        title={`Cancel ${tabPanel === 'sold' ? 'sale' : tabPanel}`}
        onClick={() => setModalType(ModalType.CANCEL_ITEM)}
        color="error"
      />
      <CustomButton
        icon={<ContentCopyIcon />}
        title="Duplicate Item"
        onClick={() => setModalType(ModalType.DUPLICATE_ITEM)}
        variant="outline"
      />
      {tabPanel === 'sold' && <CustomButton disabled={isLoading} icon={<AutorenewIcon />} title="Process" onClick={markAsProcessed} />}
    </Stack>
  )
}

export default ProcessedItemBtn
