import {createId} from '@paralleldrive/cuid2'
import {_replaceWildCards} from 'util/string_utils'
import {_replaceWithWildcards} from 'util/string_utils'
import {create} from 'zustand'
import {persist, createJSONStorage} from 'zustand/middleware'

export const BulkScanInAutofill = {
  SCAN: {
    DEFAULT: 'DEFAULT',
    CONTINUES: 'CONTINUES',
  },
}

export const useBulkScanInStore = create(
  persist(
    (set, get) => ({
      settings: {
        autofill: {
          scan: BulkScanInAutofill.SCAN.DEFAULT,
        },
        isExpanded: true,
      },
      payload: {
        location: null,
        products: [],
      },
      reset: () => {
        set((state) => ({
          ...state,
          settings: {
            autofill: {
              scan: BulkScanInAutofill.SCAN.DEFAULT,
            },
            isExpanded: true,
          },
          payload: {
            location: null,
            products: [],
          },
        }))
      },
      updateSettings: (settings) =>
        set((state) => ({
          ...state,
          settings: {
            ...state.settings,
            ...settings,
          },
        })),
      updateLocation: (location) =>
        set((state) => ({
          ...state,
          payload: {
            ...state.payload,
            location: location,
          },
        })),
      addProduct: (product, {descriptionWildcardList}) => {
        const {products} = get().payload
        const settings = get().settings

        const description = _replaceWildCards(
          {...product, description: descriptionWildcardList.join(' ')},
          {},
          descriptionWildcardList,
        )

        let autofilledProduct = {}
        let baseProduct = {
          id: createId(),
          createdAt: new Date().toISOString(),
          internalSku: product.internalSku,
          assets: product.assets,
          description: description,
          sku: product.sku,
          title: product.title,
          size: product.size,
          category: product.category,
        }

        switch (settings?.autofill?.scan) {
          case BulkScanInAutofill.SCAN.DEFAULT:
            autofilledProduct = product
            break
          case BulkScanInAutofill.SCAN.CONTINUES:
            autofilledProduct =
              products.length >= 1
                ? {
                    ...products[products.length - 1],
                  }
                : product
            break
        }

        set((state) => ({
          ...state,
          payload: {
            ...state.payload,
            products: [
              ...state.payload?.products,
              {
                ...autofilledProduct,
                ...baseProduct,
              },
            ],
          },
        }))
      },
      updateProducts: (id, {key, value}, descriptionWildcardList) => {
        const productIndex = get().payload.products.findIndex((product) => product.id === id)

        set((state) => ({
          ...state,
          payload: {
            ...state.payload,
            products: state.payload?.products.map((product, index) => {
              const description = _replaceWildCards(
                {...product, [key]: value, description: descriptionWildcardList.join(' ')},
                {},
                descriptionWildcardList,
              )

              if (productIndex < index) {
                return {
                  ...product,
                  [key]: value,
                  description,
                }
              }
              return product
            }),
          },
        }))
      },
      updateProduct: (id, {key, value}, descriptionWildcardList) => {
        set((state) => ({
          ...state,
          payload: {
            ...state.payload,
            products: state.payload?.products.map((product, index) => {
              const description = _replaceWildCards(
                {...product, [key]: value, description: descriptionWildcardList.join(' ')},
                {},
                descriptionWildcardList,
              )

              if (product.id === id) {
                return {
                  ...product,
                  [key]: value,
                  description,
                }
              } else {
                return product
              }
            }),
          },
        }))
      },
      duplicateProduct: (id, {count, internalSkus, descriptionWildcardList}) => {
        const product = get().payload.products.find((product) => product.id === id)

        const description = _replaceWildCards(
          {...product, description: descriptionWildcardList.join(' ')},
          {},
          descriptionWildcardList,
        )

        set((state) => ({
          ...state,
          payload: {
            ...state.payload,
            products: [
              ...state.payload?.products,
              ...Array.from({length: count}).map((_, index) => ({
                ...product,
                id: createId(),
                internalSku: internalSkus[index],
                createdAt: new Date().toISOString(),
                description: description,
              })),
            ],
          },
        }))
      },
      removeProduct: (id) =>
        set((state) => ({
          ...state,
          payload: {
            ...state.payload,
            products: state.payload?.products.filter((product) => product.id !== id),
          },
        })),
      clearProducts: () =>
        set((state) => ({
          ...state,
          payload: {
            ...state.payload,
            products: [],
          },
        })),
    }),
    {
      name: 'bulk-scan-in-store',
      storage: createJSONStorage(() => localStorage),
    },
  ),
)
