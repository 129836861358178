import React, {useEffect, useState} from 'react'
import {
  Box,
  IconButton,
  Typography,
  Stack,
  FormControlLabel,
  Checkbox,
  CircularProgress,
  Chip,
  LinearProgress,
  useMediaQuery,
} from '@mui/material'
import {Edit as EditIcon} from '@mui/icons-material'
import {isEmpty} from 'lodash'
import Select from 'react-select'

import {ModalType} from 'enums'
import {CopytLabel, CopytButton, FieldGroup} from 'views_v2/lib/snippets'
import {_replaceWildCards, _getValue, formatName} from 'util/string_utils'
import {getCurrency} from 'util/model/setting'
import {metricSize, weightSize} from 'assets/data/options/dimension_options'
import {useShipments} from 'service/hook'
import mw from '../middleware'
import {capitalizeText} from 'util/string_utils'
import MdlIncludedItems from './MdlncludedItems'
import CopytModal from 'components/CopytModal'
import {PromoCode} from 'views_v2/lib/snippets'
import MdlDiscountCode from './MdlDiscountCode'

const MdlPurchaseLabel = (p) => {
  const {
    shipment,
    isLoading,
    setModalType,
    addresses,
    setAddresses,
    parcel,
    setParcel,
    isPurchasingShipment,
    isGeneratingShipment,
    updateShipment,
    isUpdatingShipment,
    discounts,
    applyShipmentDiscount,
    isApplyingShipmentDiscount,
    applyDiscount,
    isApplyingDiscount,
    isIncludeDuplicates,
    setIsIncludeDuplicates,
  } = p
  const [rate, setRate] = useState()
  const [isCancel, setIsCancel] = useState(false)
  const currency = getCurrency()
  const [isSignature, setIsSignature] = useState(
    shipment?.shippo?.shipment?.extra?.signature_confirmation === 'STANDARD' || undefined,
  )
  const [insurance, setInsurance] = useState(shipment?.shippo?.shipment?.extra?.insurance || undefined)
  const {isErrorPurchaseShipment} = useShipments()
  const [isOpenIncludedItems, setIsOpenIncludedItems] = useState(false)
  const [isOpenDiscounts, setIsOpenDiscounts] = useState(false)
  const [isPurchaseConfirmed, setIsPurchaseConfirmed] = useState(false)
  const isMobile = useMediaQuery('(max-width:1000px)')

  const calculatePricing = ({rate, parcel, discount}) => {
    const convertToPounds = (weight, unit) => {
      switch (unit) {
        case 'g':
          return weight / 453.592
        case 'kg':
          return weight * 2.20462
        case 'oz':
          return weight / 16
        case 'lb':
        default:
          return weight
      }
    }

    const {weight, mass_unit} = parcel

    const weightInPounds = convertToPounds(Number(weight), mass_unit)

    const lowPercentage = Number(rate.amount) * 0.15
    const highPercentage = Number(rate.amount) * 0.3

    const fee = Number(weightInPounds) > 10 ? highPercentage : lowPercentage
    const originalPrice = Number(rate.amount)
    const price = originalPrice + fee
    let discountedPrice = price

    if (discount) {
      if (discount.type === 'PERCENTAGE') {
        discountedPrice -= price * (Number(discount.value) / 100)
      } else if (discount.type === 'FIXED') {
        discountedPrice -= Number(discount.value)
      }
    }

    return {
      price,
      discountedPrice,
      fee,
    }
  }

  useEffect(() => {
    setIsPurchaseConfirmed(false)
  }, [rate])

  useEffect(() => {
    if ((isEmpty(parcel) || isEmpty(addresses?.recipient)) && !isCancel) {
      setIsCancel(true)
    }
  }, [shipment, addresses, parcel])

  useEffect(() => {
    setRate(undefined)
  }, [insurance, isSignature, shipment])

  useEffect(() => {
    const shippoShipmentCreatedAt = shipment?.shippo?.shipment?.object_created

    if (shippoShipmentCreatedAt) {
      const EIGHTEEN_HOURS_IN_MS = 18 * 60 * 60 * 1000

      const createdAtDate = new Date(shippoShipmentCreatedAt)
      const now = new Date()
      const timeDifference = now - createdAtDate

      if (timeDifference > EIGHTEEN_HOURS_IN_MS) {
        setModalType(ModalType.ADDRESS)
      }
    }
  }, [])

  useEffect(() => {
    if (!isEmpty(addresses?.recipient) || !isEmpty(addresses?.sender) || !isEmpty()) return
    console.log('BOOOOM')
    setAddresses({
      recipient: shipment.recipientAddress,
      sender: shipment.senderAddress,
    })
    setParcel(shipment.parcel)
  }, [])

  const isTest = shipment?.shippo?.shipment?.test
  const duplicates = shipment?.duplicates?.filter((duplicate) => !duplicate.isPaid).length || 0

  const sortRates = (rates) => {
    const sortOrder = [
      'ups_ground',
      'ups_3_day_select',
      'ups_second_day_air',
      'ups_next_day_air_saver',
      'ups_next_day_air',
      'ups_next_day_air_early_am',
    ]

    return rates.sort((a, b) => {
      const tokenA = a.servicelevel.token
      const tokenB = b.servicelevel.token

      const indexA = sortOrder.findIndex((type) => tokenA.includes(type))
      const indexB = sortOrder.findIndex((type) => tokenB.includes(type))

      if (indexA === -1 && indexB === -1) return 0
      if (indexA === -1) return 1
      if (indexB === -1) return -1

      return indexA - indexB
    })
  }

  return (
    <Box className="bulk-edit">
      <Box className="modal-title">
        <Box className="modal__heading" mx="0px !important">
          <Stack direction="row" justifyContent="space-between" alignItems="center" pr="20px">
            <Typography variant="h1" textAlign="left">
              <span>Purchase Label</span>{' '}
              <Chip label={capitalizeText(shipment?.type)} color="warning" size="small" />
            </Typography>
          </Stack>
        </Box>
      </Box>

      {isGeneratingShipment || isPurchasingShipment ? (
        <LinearProgress color="secondary" />
      ) : (
        <Stack height="4px"></Stack>
      )}

      <Box overflow="auto" pt={2}>
        <Box className="modal-body" maxHeight="calc(100vh - 200px)">
          {isLoading ? (
            <Stack justifyContent="center" alignItems="center" my={7}>
              <CircularProgress size={24} />
              <Typography color="var(--gray) !important">Please wait...</Typography>
            </Stack>
          ) : (
            <>
              <Stack
                px={2}
                direction={isMobile ? 'column' : 'row'}
                justifyContent="space-between"
                spacing={5}
              >
                {isMobile && (
                  <Stack direction="column" gap={2}>
                    <Stack
                      direction="row"
                      p={1}
                      borderRadius="6px"
                      border="1px solid var(--lighter)"
                      alignItems="flex-start"
                      bgcolor="var(--lighter)"
                      spacing={2}
                      justifyContent="space-between"
                      minHeight="90px"
                    >
                      <Stack direction="column">
                        <Typography fontSize="1rem" fontWeight={600} lineHeight="20px" marginBottom={1}>
                          Sender
                        </Typography>
                        <Typography fontSize="0.85rem" fontWeight={500} lineHeight="18px">
                          {shipment?.senderAddress?.name}
                        </Typography>
                        <Typography fontSize="0.85rem" lineHeight="16px" fontWeight={500}>
                          {mw.formatAddress(shipment?.senderAddress)}
                        </Typography>
                      </Stack>
                      <IconButton
                        size="small"
                        onClick={() => setModalType(ModalType.ADDRESS)}
                        sx={{border: '1px solid !important'}}
                        disabled={isPurchasingShipment || isGeneratingShipment}
                      >
                        <EditIcon />
                      </IconButton>
                    </Stack>
                    <Stack
                      direction="row"
                      p={1}
                      borderRadius="6px"
                      border="1px solid var(--lighter)"
                      alignItems="flex-start"
                      bgcolor="var(--lighter)"
                      spacing={2}
                      justifyContent="space-between"
                      minHeight="90px"
                    >
                      <Stack direction="column">
                        <Typography fontSize="1rem" fontWeight={600} lineHeight="20px" marginBottom={1}>
                          Recipient
                        </Typography>
                        <Typography fontSize="0.85rem" fontWeight={500} lineHeight="18px">
                          {shipment?.recipientAddress?.name}
                        </Typography>
                        <Typography fontSize="0.85rem" lineHeight="16px" fontWeight={500}>
                          {mw.formatAddress(shipment?.recipientAddress)}
                        </Typography>
                      </Stack>
                      <IconButton
                        size="small"
                        onClick={() => setModalType(ModalType.ADDRESS)}
                        sx={{border: '1px solid !important'}}
                        disabled={isPurchasingShipment || isGeneratingShipment}
                      >
                        <EditIcon />
                      </IconButton>
                    </Stack>
                  </Stack>
                )}

                {/* Left */}
                <Stack width={isMobile ? '100%' : '50%'} spacing={3}>
                  {!isMobile && (
                    <Stack
                      direction="row"
                      p={1}
                      borderRadius="6px"
                      border="1px solid var(--lighter)"
                      alignItems="flex-start"
                      bgcolor="var(--lighter)"
                      spacing={2}
                      justifyContent="space-between"
                      minHeight="90px"
                    >
                      <Stack direction="column">
                        <Typography fontSize="1rem" fontWeight={600} lineHeight="20px" marginBottom={1}>
                          Sender
                        </Typography>
                        <Typography fontSize="0.85rem" fontWeight={500} lineHeight="18px">
                          {shipment?.senderAddress?.name}
                        </Typography>
                        <Typography fontSize="0.85rem" lineHeight="16px" fontWeight={500}>
                          {mw.formatAddress(shipment?.senderAddress)}
                        </Typography>
                      </Stack>
                      <IconButton
                        size="small"
                        onClick={() => setModalType(ModalType.ADDRESS)}
                        sx={{border: '1px solid !important'}}
                        disabled={isPurchasingShipment || isGeneratingShipment}
                      >
                        <EditIcon />
                      </IconButton>
                    </Stack>
                  )}
                  <Stack>
                    <Stack direction="row" gap={1} alignItems="end">
                      <FieldGroup
                        readOnly
                        id="numeric"
                        fixedDecimalScale={false}
                        decimalScale={4}
                        sLabel="Length"
                        defaultValue={parseFloat(parcel?.length || shipment?.parcel?.length)}
                      />
                      <Typography style={{marginBottom: '15px', marginX: '2px'}}>×</Typography>
                      <FieldGroup
                        readOnly
                        id="numeric"
                        fixedDecimalScale={false}
                        decimalScale={4}
                        sLabel="Width"
                        defaultValue={parseFloat(parcel?.width || shipment?.parcel?.width)}
                      />
                      <Typography style={{marginBottom: '15px', marginX: '2px'}}>×</Typography>
                      <FieldGroup
                        readOnly
                        id="numeric"
                        fixedDecimalScale={false}
                        decimalScale={4}
                        sLabel="Height"
                        defaultValue={parseFloat(parcel?.height || shipment?.parcel?.height)}
                      />
                      <Box width="100%" marginBottom="10px">
                        <Select
                          className="react-select info"
                          classNamePrefix="react-select"
                          closeMenuOnSelect
                          isDisabled
                          value={{value: parcel?.distance_unit, label: parcel?.distance_unit}}
                          styles={{
                            control: (base) => ({
                              ...base,
                              borderColor: 'var(--lighter) !important',
                            }),
                          }}
                          options={metricSize}
                        />
                      </Box>
                    </Stack>
                  </Stack>
                  <Stack width="45%">
                    <Stack direction="row" gap={1} alignItems="end">
                      <FieldGroup
                        readOnly
                        id="numeric"
                        fixedDecimalScale={false}
                        decimalScale={4}
                        sLabel="Weight"
                        defaultValue={parseFloat(parcel?.weight || shipment?.parcel?.weight)}
                      />
                      <Box width="100%" marginBottom="10px">
                        <Select
                          className="react-select info"
                          classNamePrefix="react-select"
                          closeMenuOnSelect
                          isDisabled
                          value={{
                            value: parcel?.mass_unit || shipment?.parcel?.mass_unit,
                            label: parcel?.mass_unit || shipment?.parcel?.mass_unit,
                          }}
                          styles={{
                            control: (base) => ({
                              ...base,
                              borderColor: 'var(--lighter) !important',
                            }),
                          }}
                          options={weightSize}
                        />
                      </Box>
                    </Stack>
                  </Stack>
                  <Stack pl={2}>
                    <FormControlLabel
                      control={<Checkbox checked={isSignature} />}
                      disabled={isPurchasingShipment || isGeneratingShipment}
                      onChange={() => {
                        setIsSignature(!isSignature)
                        p?.onSaveChanges({
                          generateShipment: {
                            signature_confirmation: {
                              value: !isSignature,
                            },
                          },
                        })
                      }}
                      label="Require a signature for delivery"
                      sx={{
                        '& .MuiTypography-root': {
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          marginLeft: '10px',
                        },
                        pb: '20px',
                      }}
                    />
                    {duplicates ? (
                      <Box marginTop="-20px">
                        <FormControlLabel
                          control={<Checkbox checked={isIncludeDuplicates} />}
                          disabled={isPurchasingShipment || isGeneratingShipment}
                          onChange={() => {
                            setIsIncludeDuplicates(!isIncludeDuplicates)
                          }}
                          label={`Include duplicates (${duplicates})`}
                          sx={{
                            '& .MuiTypography-root': {
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              marginLeft: '10px',
                            },
                            pb: '20px',
                          }}
                        />
                      </Box>
                    ) : null}
                  </Stack>
                </Stack>
                {/* Right */}
                <Stack width={isMobile ? '100%' : '50%'} spacing={3}>
                  {!isMobile && (
                    <Stack
                      direction="row"
                      p={1}
                      borderRadius="6px"
                      border="1px solid var(--lighter)"
                      alignItems="flex-start"
                      bgcolor="var(--lighter)"
                      spacing={2}
                      justifyContent="space-between"
                      minHeight="90px"
                    >
                      <Stack direction="column">
                        <Typography fontSize="1rem" fontWeight={600} lineHeight="20px" marginBottom={1}>
                          Recipient
                        </Typography>
                        <Typography fontSize="0.85rem" fontWeight={500} lineHeight="18px">
                          {shipment?.recipientAddress?.name}
                        </Typography>
                        <Typography fontSize="0.85rem" lineHeight="16px" fontWeight={500}>
                          {mw.formatAddress(shipment?.recipientAddress)}
                        </Typography>
                      </Stack>
                      <IconButton
                        size="small"
                        onClick={() => setModalType(ModalType.ADDRESS)}
                        sx={{border: '1px solid !important'}}
                        disabled={isPurchasingShipment || isGeneratingShipment}
                      >
                        <EditIcon />
                      </IconButton>
                    </Stack>
                  )}
                  <Stack spacing={1}>
                    <Box>
                      <Typography fontSize="0.85rem" fontWeight={600} color="var(--success) !important">
                        Insurance
                      </Typography>
                      <Stack direction="row" alignItems="end" gap={2}>
                        <FieldGroup
                          id="numeric"
                          readOnly={isPurchasingShipment || isGeneratingShipment}
                          sLabel="Coverage Amount"
                          value={insurance?.amount}
                          onChange={(event) => {
                            setInsurance((prevState) => ({...prevState, amount: event.target.value}))
                          }}
                        />
                        <Box marginBottom={1.2}>
                          <CopytButton
                            variant="outline"
                            sx={{
                              width: '150px',
                              borderRadius: '6px !important',
                              height: '38px',
                            }}
                            disabled={isPurchasingShipment || isGeneratingShipment}
                            onClick={() => {
                              p?.onSaveChanges({
                                generateShipment: {
                                  insurance: insurance
                                    ? {
                                        value: {
                                          amount: insurance?.amount || 0,
                                          currency: 'USD',
                                          content: 'Shoe',
                                        },
                                      }
                                    : undefined,
                                },
                              })
                            }}
                          >
                            Apply
                          </CopytButton>
                        </Box>
                      </Stack>
                    </Box>
                    <Stack gap={2}>
                      <CopytButton
                        disabled={isPurchasingShipment || isGeneratingShipment}
                        onClick={() => {
                          setIsOpenDiscounts(true)
                        }}
                        variant="outline"
                        color="secondary"
                        style={{padding: '6px 16px', borderRadius: '4px'}}
                      >
                        Discounts
                      </CopytButton>
                      {shipment?.discount && <PromoCode discount={shipment?.discount} />}
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>

              {shipment?.event && (
                <Stack p={2}>
                  <Typography fontSize="0.9rem" color="red !important">
                    {shipment?.event?.notice}
                  </Typography>
                </Stack>
              )}

              <Stack
                p={2}
                sx={{
                  display: 'grid',
                  gridTemplateColumns: isMobile ? 'repeat(1fr)' : 'repeat(2, 1fr)',
                  gap: 2,
                  maxHeight: '350px',
                  overflowY: 'auto',
                  pointerEvents: isPurchasingShipment || isGeneratingShipment ? 'none' : 'auto',
                }}
              >
                {sortRates(sortRates(shipment?.shippo?.shipment?.rates || [])).map((_rate, index) => {
                  const isActive = _rate?.object_id === rate
                  const {
                    price: initialPrice,
                    discountedPrice: initialDiscountedPrice,
                    fee,
                  } = calculatePricing({
                    rate: _rate,
                    parcel: shipment.parcel,
                    discount: shipment?.discount,
                  })

                  const withDuplicatePrice = shipment?.duplicates
                    ?.filter((duplicate) => !duplicate.isPaid)
                    .reduce((accumulator, current) => {
                      const rate = current?.shippo?.shipment?.rates.find((r) => {
                        return r?.servicelevel?.token === _rate?.servicelevel?.token
                      })

                      if (rate) {
                        const {price} = calculatePricing({
                          rate: rate,
                          parcel: shipment.parcel,
                          discount: shipment?.discount,
                        })
                        return accumulator + price
                      }

                      return accumulator
                    }, initialPrice)

                  const withDuplicateDiscountedPrice = shipment?.duplicates
                    ?.filter((duplicate) => !duplicate.isPaid)
                    .reduce((accumulator, current) => {
                      const rate = current?.shippo?.shipment?.rates.find((r) => {
                        return r?.servicelevel?.token === _rate?.servicelevel?.token
                      })

                      if (rate) {
                        const {discountedPrice} = calculatePricing({
                          rate: rate,
                          parcel: shipment.parcel,
                          discount: shipment?.discount,
                        })
                        return accumulator + discountedPrice
                      }

                      return accumulator
                    }, initialDiscountedPrice)

                  let price = isIncludeDuplicates ? withDuplicatePrice : initialPrice
                  let discountedPrice = isIncludeDuplicates
                    ? withDuplicateDiscountedPrice
                    : initialDiscountedPrice

                  return (
                    <Box
                      key={index}
                      px={1}
                      border="2px solid"
                      bgcolor={isActive ? 'var(--primary-light)' : 'var(--gray-light)'}
                      borderColor={isActive ? 'var(--default)' : 'var(--lighter)'}
                      borderRadius="6px"
                      isDisabled
                      onClick={() => setRate(_rate?.object_id)}
                      sx={{
                        '&:hover, &:active': {
                          outline: '2px solid',
                          background: 'var(--lighter)',
                        },
                        cursor: 'pointer',
                        filter:
                          isPurchasingShipment || isGeneratingShipment ? 'grayscale(100%)' : 'none',
                      }}
                    >
                      {isTest && (
                        <Stack borderBottom="1px solid">
                          <Typography fontSize={12}>
                            ${Number(initialPrice).toFixed(2)} = Initial Rate Price
                          </Typography>
                          <Typography fontSize={12}>
                            ${Number(_rate?.amount).toFixed(2)} = Original Rate Price
                          </Typography>
                          <Typography fontSize={12}>
                            ${Number(fee).toFixed(2)} = Copyt Charge Fee
                          </Typography>
                          <Typography fontSize={12}>
                            ${Number(_rate?.included_insurance_price).toFixed(2)} = Included Insurance
                            Price
                          </Typography>
                        </Stack>
                      )}
                      <Stack direction="row" alignItems="center" justifyContent="space-between">
                        <Stack direction="row" alignItems="center" gap={2} paddingY={1}>
                          <Box component="img" width={36} src={_rate?.provider_image_200} />
                          <Stack>
                            <Typography fontWeight={600}>{_rate?.servicelevel?.name}</Typography>
                            <Stack direction="row" gap={1}>
                              {_rate?.attributes?.map((attribute, index) => (
                                <React.Fragment key={attribute}>
                                  <Typography fontSize={12}>{attribute}</Typography>
                                  {index < _rate.attributes.length - 1 && (
                                    <Typography fontSize={12}>•</Typography>
                                  )}
                                </React.Fragment>
                              ))}
                            </Stack>
                          </Stack>
                        </Stack>
                        <Stack>
                          <Stack direction="row" gap={1}>
                            {!shipment?.discount ? (
                              <Typography fontWeight={600} color="black" textAlign="right">
                                {currency.format(Number(price))}
                              </Typography>
                            ) : (
                              <Stack direction="row" alignItems="center" gap={1} textAlign="right">
                                <Typography
                                  sx={{
                                    textDecoration: 'line-through',
                                  }}
                                  fontSize={14}
                                  color="gray"
                                >
                                  {currency.format(Number(price))}
                                </Typography>
                                <span> - </span>
                                <Typography fontWeight={600} color="black">
                                  {currency.format(Number(discountedPrice))}
                                </Typography>
                              </Stack>
                            )}
                            {isIncludeDuplicates ? (
                              <Typography fontSize={12} color="black">
                                {'('}
                                {duplicates + 1}
                                {')'}
                              </Typography>
                            ) : null}
                          </Stack>
                          <Typography
                            textAlign="right"
                            fontSize="0.9rem"
                            color="var(--default) !important"
                          >
                            {_rate?.estimated_days} days
                          </Typography>
                        </Stack>
                      </Stack>
                    </Box>
                  )
                })}
              </Stack>
            </>
          )}
          {/* Footer */}
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            px={2}
            pt={1.5}
            mt={4}
            pb={2}
            borderTop="1px solid var(--lighter)"
          >
            {isErrorPurchaseShipment && (
              <Typography fontStyle="italic" fontSize="0.85rem" color="red !important">
                Purchase failed! Please choose other rate
              </Typography>
            )}
            <Box />
            <Stack direction="row" spacing={2}>
              <CopytButton
                color="primary"
                disabled={isPurchasingShipment || isGeneratingShipment}
                sx={{
                  marginRight: 'auto',
                  borderRadius: '6px !important',
                  height: '35px',
                  textAlign: 'left',
                }}
                onClick={() => setIsOpenIncludedItems(true)}
              >
                {shipment?.products?.length || 0}{' '}
                {shipment?.products?.length > 1 ? 'Items Included' : 'Item Included'}
              </CopytButton>
              <CopytButton
                variant="outline"
                color="primary"
                disabled={isPurchasingShipment || isGeneratingShipment}
                sx={{
                  width: '100px',
                  borderRadius: '6px !important',
                  height: '35px',
                }}
                onClick={() => setModalType(isCancel ? ModalType.UNDEFINED : ModalType.ADDRESS)}
              >
                {isCancel ? 'Cancel' : 'Back'}
              </CopytButton>
              <CopytButton
                variant="contained"
                color="primary"
                sx={{
                  width: '160px',
                  borderRadius: '6px !important',
                  height: '35px',
                }}
                disabled={(!rate && isLoading) || isPurchasingShipment || isGeneratingShipment || !rate}
                onClick={async () => {
                  if (!isPurchaseConfirmed) {
                    setIsPurchaseConfirmed(true)
                    return
                  }
                  await p?.onSaveChanges({
                    purchase: {
                      rate: rate,
                    },
                  })
                  setIsPurchaseConfirmed(false)
                }}
              >
                {isPurchasingShipment
                  ? 'Purchasing...'
                  : isPurchaseConfirmed
                  ? 'Confirm Purchase'
                  : 'Purchase'}
              </CopytButton>
            </Stack>
          </Stack>
        </Box>
      </Box>
      <CopytModal
        className="no-padding"
        id="create-box-preset"
        show={isOpenIncludedItems}
        size="xl"
        toggle={() => null}
        closeButton={{
          hidden: true,
        }}
      >
        <MdlIncludedItems
          shipment={shipment}
          onCloseModal={() => setIsOpenIncludedItems(false)}
          updateShipment={updateShipment}
          isUpdatingShipment={isUpdatingShipment}
        />
      </CopytModal>

      <CopytModal
        className="no-padding"
        id="create-box-preset"
        show={isOpenDiscounts}
        size="sm"
        toggle={() => null}
        closeButton={{
          hidden: true,
        }}
      >
        <MdlDiscountCode
          canUseDiscount
          discounts={discounts}
          shipment={shipment}
          onCloseModal={() => setIsOpenDiscounts(false)}
          applyShipmentDiscount={applyShipmentDiscount}
          isApplyingShipmentDiscount={isApplyingShipmentDiscount}
          applyDiscount={applyDiscount}
          isApplyingDiscount={isApplyingDiscount}
        />
      </CopytModal>
    </Box>
  )
}

export default MdlPurchaseLabel
