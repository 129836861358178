import React, {useEffect, useState, useRef, useCallback} from 'react'
import {useHistory, useParams} from 'react-router-dom'
import {Box, Grid, Stack} from '@mui/material'
import {
  ContentCopy as ContentCopyIcon,
  EditNote as EditNoteIcon,
  QrCodeScanner as QrCodeScannerIcon,
  SettingsBackupRestore,
  ShoppingCart as ShoppingCartIcon,
} from '@mui/icons-material'
import {useReactToPrint} from 'react-to-print'
import {flatMap, isEqual, times} from 'lodash'

import DetailView from 'components/DetailView'
import {useGlobalStore} from 'provider/global_store/hook'
import {ToastType, ModalType} from 'enums'
import useEnterKeyListener from 'hooks/useEnterKeyListener'
import {useUser} from 'service/hook'
import {useListing} from 'service/hook/use_listing'
import {CopytToolbar, LoadingBar} from 'views_v2/lib/snippets'
import {CustomButton} from 'views_v2/modules/Inventory/snippets/headers'
import {
  Overview,
  ConsignorInformation,
  InventoryImages,
  InventoryPricing,
  InventoryTracking,
  Notes,
} from 'views_v2/modules/Inventory/components/cards'
import {ListingInventoryDetails} from 'views_v2/modules/Listings'
import InventoryToast from 'views_v2/modules/Inventory/components/InventoryToast'
import InventoryModals from 'views_v2/modules/Inventory/components/InventoryModals'
import {BarcodeLabels} from 'views_v2/lib/components'
import {forRemove, isConsigned} from 'util/model/product'
import {useItem} from 'service/hook/useItem'
import {useToastMessage} from 'components'

const ViewListing = () => {
  const {id} = useParams()
  const {isEnterprise} = useGlobalStore()
  const {user} = useUser()
  const {postItemDuplicate} = useItem()
  const {showToast} = useToastMessage()
  const componentRef = useRef()
  const history = useHistory()

  const [loading, setLoading] = useState(false)
  const [formFields, setFormFields] = useState({quantity: 1, shippingCost: '0.00'})
  const [toastType, setToastType] = useState(ToastType.UNDEFINED)
  const [modalType, setModalType] = useState(ModalType.UNDEFINED)
  const [printSize, setPrintSize] = useState({width: 3.5, height: 1.2})
  const [barcodeTemplates, setBarcodeTemplates] = useState([])
  const [isBarcodeLabelReady, setIsBarcodeLabelReady] = useState(false)
  const [previousListingData, setPreviousListingData] = useState(null)
  const [showBarcodeTemplate, setShowBarcodeTemplate] = useState({})
  const [duplicateSizes, setDuplicateSizes] = useState([{}])

  const routeChange = useCallback(
    (route, data) => {
      history.push({pathname: route, data: data})
    },
    [history],
  )

  useEnterKeyListener({
    querySelectorToExecuteClick: '#markAsSoldConfirmBtn',
  })

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    suppressErrors: true,
    documentTitle: `Copyt - ${printSize.width}x${printSize.height}`,
  })

  const onAddSize = useCallback(() => setDuplicateSizes((v) => [...v, {}]), [])

  const handleDuplicate = useCallback(async () => {
    setLoading(true)

    const dupeArr = flatMap(duplicateSizes, (size) =>
      times(size.count, () => (size.size ? {size: size.size, desiredReturn: size.return} : null)),
    ).filter(Boolean)

    await postItemDuplicate(
      {productId: product?.id, sizes: dupeArr},
      () => {
        setDuplicateSizes([{}])
        showToast({variant: 'success', body: 'Item successfully duplicated!'})
        routeChange('/admin/inventory/viewInventory')
        setLoading(false)
      },
      () => {
        showToast({variant: 'danger', body: 'Failed to duplicate.'})
        setLoading(false)
      },
    )
  }, [duplicateSizes, setDuplicateSizes, postItemDuplicate, product?.id, routeChange])

  const {listingData, error, refetch} = useListing(id, {refetchOnWindowFocus: false})

  useEffect(() => {
    const interval = setInterval(() => {
      if (listingData && !isEqual(listingData, previousListingData)) {
        refetch()
      }
    }, 5000)

    return () => clearInterval(interval)
  }, [listingData, previousListingData, refetch])

  useEffect(() => {
    if (listingData && !isEqual(listingData, previousListingData)) {
      setPreviousListingData(listingData)
    }
  }, [listingData])

  const product = listingData?.product

  if (error) {
    return (
      <div className="content">
        Failed.
        <hr />
        <button onClick={() => history.goBack()}>Back</button>
      </div>
    )
  }

  if (!listingData) return <LoadingBar />

  return (
    <>
      {isBarcodeLabelReady && (
        <Box display="none">
          <BarcodeLabels
            {...{barcodeTemplates, showBarcodeTemplate}}
            items={[listingData?.product]}
            businessName={user?.businessName || 'Copyt'}
            logo={user?.platform?.logo_url}
            url={user?.customWebsiteSearchUrl}
            ref={componentRef}
          />
        </Box>
      )}
      <Box className="p-detail-flex inventory listing">
        <CopytToolbar style={{justifyContent: 'flex-end'}}>
          <Stack direction="row" spacing={1}>
            {isEnterprise && (
              <CustomButton
                icon={<QrCodeScannerIcon />}
                title="Generate Barcode"
                onClick={() => setModalType(ModalType.BARCODE_TEMPLATE)}
                color="error"
                style={{
                  display: !product && 'disabled',
                }}
              />
            )}
            <CustomButton
              icon={<ContentCopyIcon />}
              title="Duplicate Item"
              disabled={product?.onQueue}
              onClick={() => setModalType(ModalType.DUPLICATE_ITEM)}
              variant="outline"
            />
            <CustomButton
              icon={<EditNoteIcon />}
              disabled={forRemove(listingData)}
              title="Edit Listing"
              onClick={() => routeChange(`/admin/listings/editListing/${id}`)}
              variant="outline"
              style={{
                display: !product && 'disabled',
              }}
            />

            {isConsigned(product) && !isEnterprise ? (
              <CustomButton
                disabled={forRemove(listingData)}
                icon={<SettingsBackupRestore />}
                title="Withdraw"
                onClick={() => setModalType(ModalType.WITHDRAW)}
              />
            ) : (
              <CustomButton
                disabled={forRemove(listingData)}
                icon={<ShoppingCartIcon />}
                title="Mark as Sold"
                onClick={() => setModalType(ModalType.MARK_AS_SOLD)}
              />
            )}
          </Stack>
        </CopytToolbar>
        <DetailView.PanelDetail>
          <Grid container gap={1}>
            <Grid item xs={12} lg={8}>
              <Grid container gap={2}>
                <Grid item xs={12} md={5.5} lg={5.8}>
                  <Overview formFields={product} isListing readOnly />
                </Grid>
                <Grid item xs={12} md={6} lg={5.8}>
                  <ListingInventoryDetails {...{product}} readOnly />
                </Grid>
              </Grid>
              <InventoryImages formFields={product} isListing />
            </Grid>
            <Grid item xs={12} lg={3.9}>
              {isEnterprise && (
                <InventoryTracking
                  {...{
                    isEnterprise,
                    setFormFields,
                  }}
                  isReadOnly
                  formFields={product}
                  location={product?.location}
                  internalSku={product?.internalSku}
                />
              )}
              <ConsignorInformation
                {...{setFormFields}}
                platforms={listingData.listingPlatforms}
                consignor={product?.consign || null}
                consignorEmail={product?.consign?.consignor?.email || ''}
                feeStructure={formFields?.feeStructure || product?.feeStructure}
                formFields={{
                  ...formFields,
                  desiredReturn: product?.desiredReturn,
                  feeStructure: product?.feeStructure,
                  location: product?.location,
                  sold: listingData?.solds?.[0],
                }}
                isConsignorEmailReadOnly
                isFeeStructureReadOnly
              />
              <InventoryPricing
                {...{isEnterprise}}
                isConsigned={product?.consign?.status?.toLowerCase() === 'listed'}
                formFields={product}
                readOnly
                isDesiredReturnReadOnly
              />
              <Notes editMode={false} notes={product?.note} />
            </Grid>
          </Grid>
          {toastType > ToastType.UNDEFINED && <InventoryToast {...{toastType, setToastType}} />}
          <InventoryModals
            {...{
              barcodeTemplates,
              duplicateSizes,
              formFields,
              loading,
              modalType,
              printSize,
              onAddSize,
              setFormFields,
              setBarcodeTemplates,
              setDuplicateSizes,
              setModalType,
              setPrintSize,
              setToastType,
              routeChange,
              data: [product],
              showBarcodeTemplate,
            }}
            onHide={() => setModalType(ModalType.UNDEFINED)}
            onShowBarcodeTemplate={setShowBarcodeTemplate}
            onSubmit={handleDuplicate}
            formFields={{
              ...formFields,
              consignStatus: product?.consign?.status || '',
              listingId: listingData.id,
            }}
            onGenerateBarcodesClicked={() => {
              setIsBarcodeLabelReady(true)
              setTimeout(() => {
                handlePrint()
              }, 500)
            }}
          />
        </DetailView.PanelDetail>
      </Box>
    </>
  )
}

export default ViewListing
