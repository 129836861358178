import {create} from 'zustand'

export const useFilter = create((set) => ({
  audit: {
    filter: {},
  },
  setAuditFilter: (newFilter) =>
    set((state) => ({
      audit: {
        ...state.audit,
        filter: {
          ...state.audit.filter,
          ...newFilter,
        },
      },
    })),
  clearAuditFilter: () =>
    set((state) => ({
      audit: {
        ...state.audit,
        filter: {},
      },
    })),
}))
