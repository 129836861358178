import React, {useState, useEffect, useRef} from 'react'
import axios from 'axios'
import {useAuth0} from '@auth0/auth0-react'
import {Box, InputLabel, Stack, Typography} from '@mui/material'
import {styled} from '@mui/material/styles'
import Select, {components} from 'react-select'
import {isEmpty} from 'lodash'

import useEnterKeyListener from 'hooks/useEnterKeyListener'
import {createRequestOptions} from 'util/network'
import {paths} from 'constant/api'
import {CopytButton} from 'views_v2/lib/snippets'
import {ModalContainer, ModalHeading, ModalBody, ModalFooter} from 'views_v2/lib/snippets/CopytModal'

export const getSneakData = async (getAccessTokenSilently, ctgry, val) => {
  const token = await getAccessTokenSilently()
  const sneak = await axios.get(`${paths.sneak}/category/${ctgry}/keyword/${val}`, createRequestOptions(token))

  return sneak?.data?.data || []
}

export const SearchLabel = styled(InputLabel)(() => ({
  color: 'var(--success)',
  fontSize: '0.9rem',
  marginBottom: '0',
}))

const MdlSkuUpc = (p) => {
  const {getAccessTokenSilently} = useAuth0()
  const [data, setData] = useState([])
  const [value, setValue] = useState(null)
  const [searchValue, setSearchValue] = useState()
  const [options, setOptions] = useState([])
  const [shouldSubmit, setShouldSubmit] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const innerRef = useRef()
  useEffect(() => innerRef.current && innerRef.current.focus())

  const onFormSubmit = () => {
    if (!isEmpty(value?.label)) {
      let findData = {}
      if (data?.length === 1) {
        findData = data[0]
      } else if (data?.length > 1) {
        findData = data?.find(
          (d) => d['name']?.toLowerCase() === value?.value?.toLowerCase() || d['name']?.toLowerCase() === value?.label?.toLowerCase(),
        )
      }

      setIsLoading(false)
      setShouldSubmit(false)

      p.action?.processSearchBy(findData)
      setTimeout(() => {
        p.onCloseModal()
      }, 1000)
    }
  }

  useEnterKeyListener({
    querySelectorToExecuteClick: '#skuSubmit',
  })

  const onChange = async () => {
    let data = await getSneakData(getAccessTokenSilently, 'title', searchValue)

    let arr = []

    if (Array?.isArray(data) && data?.length && data[0]) {
      arr = data?.map((s) => {
        return {
          label: s?.name || s?.styleID,
          value: s?.styleID || s?.style_id,
        }
      })
    }

    setData(data)
    setOptions(arr)
    setIsLoading(false)
    setShouldSubmit(true)
  }

  const NoOptionsMessage = (props) => {
    let noMsgLabel = 'Please hit the enter to start searching'
    if (isEmpty(options) && !isLoading && shouldSubmit) {
      noMsgLabel = `Keyword or SKU not found. Please enter the item's details manually.`
    } else if (isLoading && !shouldSubmit) {
      noMsgLabel = 'Please wait while we load the data'
    }

    return (
      <components.NoOptionsMessage {...props}>
        <span>{noMsgLabel}</span>
      </components.NoOptionsMessage>
    )
  }

  return (
    <ModalContainer>
      <Box className="modal-title">
        <ModalHeading className="modal__heading">
          <Typography variant="h1">Search Item</Typography>
        </ModalHeading>
      </Box>
      <ModalBody style={{marginBottom: 40, width: '100%'}}>
        <Stack direction="column" alignItems="center" gap={2} width="100%" px={2}>
          <Typography color="var(--default) !important">Let us auto-fill some information for you!</Typography>
          <Box p="10px 10px 15px" border="1px solid var(--gray-light)" borderRadius={2} width="100%">
            <SearchLabel>Item Detail:</SearchLabel>
            <Select
              {...{value, options}}
              ref={innerRef}
              defaultMenuIsOpen
              closeMenuOnSelect
              isClearable
              instanceId="searchValue"
              className="react-select search-value"
              classNamePrefix="react-select"
              placeholder="Search item by keyword or SKU"
              components={{NoOptionsMessage}}
              onKeyDown={(event) => {
                if (event?.key === 'Enter' && !shouldSubmit && !isLoading && value === null) {
                  setIsLoading(true)
                  onChange(searchValue)
                }
              }}
              onInputChange={(e) => {
                if (shouldSubmit) {
                  setIsLoading(false)
                  setShouldSubmit(false)
                }
                setSearchValue(e)
              }}
              onChange={(e) => setValue(e)}
              filterOption={() => true}
              styles={{
                valueContainer: (base) => ({
                  ...base,
                  width: '85% !important',
                  maxWidth: 200,
                }),
              }}
            />
          </Box>
        </Stack>
      </ModalBody>
      <ModalFooter my={1} px={2}>
        <CopytButton variant="outline" color="primary" onClick={() => p.onCloseModal()} style={{width: '100%'}}>
          Skip
        </CopytButton>
        <CopytButton variant="contained" color="primary" onClick={onFormSubmit} id="skuSubmit" style={{width: '100%'}}>
          Submit
        </CopytButton>
      </ModalFooter>
    </ModalContainer>
  )
}

export default MdlSkuUpc
