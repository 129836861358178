import React, {memo, useEffect, useState} from 'react'
import clx from 'classnames'
import {styled} from '@mui/material/styles'
import {Button, Box, Menu, MenuItem, InputLabel, FormControl, Select, TextField, Stack} from '@mui/material'
import {Close as CloseIcon, KeyboardArrowDown as KeyboardArrowDownIcon} from '@mui/icons-material'
import {isEmpty} from 'lodash'

import ArrayUtils from 'util/array_utils'
import {useGlobalStore} from 'provider/global_store/hook'
import useEnterKeyListener from 'hooks/useEnterKeyListener'
import {consignmentStatus, filterArray, statusArray} from 'assets/data/options/filter_menu'
import '../scss/parent.scss'
import {category_options} from 'assets/data'

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({theme}) => ({
  '& .MuiPaper-root': {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
  },
}))

const MenuProps = {
  MenuListProps: {
    style: {
      display: 'flex',
      flexDirection: 'column',
      padding: 13,
    },
  },
}

const showAll = (filteredKey) => filterArray.some((s) => s === filteredKey)

const FilterBody = ({filterBy, handleChange, supportedPlatforms, value, listRange}) => {
  if (filterBy === 'category') {
    const arr = [{id: 'all', title: 'All'}].concat(
      category_options.map((s) => ({
        id: s?.value,
        title: s?.label,
      })),
    )

    return (
      <FormControl sx={{minWidth: '130px'}} size="small" className={clx(filterBy, 'toolbar-filter-by')}>
        <Select MenuProps={MenuProps} onChange={handleChange} value={value}>
          {arr.map((m, i) => (
            <MenuItem value={m?.id} key={m?.id}>
              {m?.title}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    )
  } else if (showAll(filterBy)) {
    let arr = consignmentStatus
    if (filterBy === 'listingPlatformStatus') {
      arr = statusArray
    } else if (filterBy.toLowerCase().includes('platform')) {
      arr = [{id: 'all', title: 'All'}].concat(
        supportedPlatforms.map((s) => ({
          id: s?.name,
          title: s?.name,
        })),
      )
    }

    return (
      <FormControl sx={{minWidth: '130px'}} size="small" className={clx(filterBy, 'toolbar-filter-by')}>
        {/* className="toolbar-filter-by"  */}
        <Select MenuProps={MenuProps} onChange={handleChange} value={value}>
          {arr.map((m, i) => (
            <MenuItem value={m?.id} key={m?.id}>
              {m?.title}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    )
  } else if (filterBy === 'listPriceRange') {
    return (
      <Stack direction="row" gap={1}>
        <TextField
          value={listRange.minListPrice}
          name="minListPrice"
          className="txtfield-value"
          label="Min Price"
          onChange={handleChange}
          onKeyDown={(e) => e.stopPropagation()}
          sx={{width: '100px'}}
          type="number"
        />
        <TextField
          name="maxListPrice"
          value={listRange.maxListPrice}
          className="txtfield-value"
          label="Max Price"
          onChange={handleChange}
          onKeyDown={(e) => e.stopPropagation()}
          sx={{width: '100px'}}
          type="number"
        />
      </Stack>
    )
  }

  return <TextField value={value} className="txtfield-value" label="Value" onChange={handleChange} onKeyDown={(e) => e.stopPropagation()} />
}

export const FilterToolbar = memo((p) => {
  const {disabled, filterMenu, queries, onSaveChanges, _style} = p
  const {supportedPlatforms} = useGlobalStore()
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const [filterBy, setFilterBy] = useState('title')
  const [value, setValue] = useState('')
  const [listRange, setListRange] = useState({
    minListPrice: '',
    maxListPrice: '',
  })

  const handleClick = (event) => setAnchorEl(event.currentTarget)

  const handleClose = () => setAnchorEl(null)

  useEnterKeyListener({
    querySelectorToExecuteClick: '#onApply',
  })

  useEffect(() => {
    if (filterBy === 'category' && value === '') {
      setValue('all')
    }
  }, [filterBy])

  useEffect(() => {
    if (Object.keys(queries)?.length > 0) {
      if (queries?.name) {
        setFilterBy('title')
        setValue(queries?.name)
      }
      const arr = filterMenu?.map((s) => s?.id)
      const query = ArrayUtils.compareObjAndArr(arr, queries)
      Object.entries(query).map(([key, value]) => {
        setFilterBy(key)
        setValue(value)
      })
    }
    if (filterBy === '' && filterMenu?.length > 0) {
      setFilterBy(filterMenu[0]?.id)
    }
  }, [p?.filterMenu, p?.queries])

  const onApply = (e) => {
    e?.stopPropagation()
    const key = filterBy === 'title' ? 'name' : filterBy
    if (filterBy === 'platform' && value === 'all') {
      onSaveChanges({
        filter: {
          platform: '',
        },
      })
    } else if (filterBy === 'listPriceRange') {
      onSaveChanges({
        filter: {
          ...queries,
          ...listRange,
        },
      })
    } else {
      onSaveChanges({
        filter: {
          [key]: value,
          ...listRange,
        },
      })
    }

    setAnchorEl(null)
  }

  const handleChange = (event) => {
    if (filterBy === 'listPriceRange') {
      const {name, value} = event.target
      setListRange({...listRange, [name]: value})
    } else {
      setValue(event.target.value)
    }
  }

  const resetFilterValue = () => {
    setValue('')
    setListRange({
      minListPrice: '',
      maxListPrice: '',
    })
  }

  return (
    <div className="display-flex">
      <Button
        {..._style}
        {...{disabled}}
        id="customized-filter-button"
        className="options-btn"
        aria-controls={open ? 'customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
        sx={{
          '&:hover': {
            backgroundColor: 'transparent',
          },
        }}
      >
        Filter
      </Button>
      <StyledMenu
        id="customized-menu"
        MenuListProps={{
          'aria-labelledby': 'customized-filter-button',
          className: 'filter-list',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <Box sx={{display: 'flex', flexDirection: 'row', p: 2}}>
          <FormControl sx={{minWidth: '130px'}} size="small" className="toolbar-filter-by">
            <InputLabel>Filter By</InputLabel>
            <Select
              value={filterBy}
              label="Filter By"
              onChange={(e) => {
                if (showAll(e.target.value)) {
                  setValue('all')
                } else {
                  resetFilterValue()
                }
                setFilterBy(e.target.value)
              }}
              MenuProps={MenuProps}
            >
              {filterMenu?.map((m, i) => (
                <MenuItem value={m?.id} key={m?.id}>
                  {m?.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Box
            sx={{
              ml: 1,
              display: 'flex',
            }}
            className="txtfield"
          >
            <FilterBody {...{filterBy, handleChange, supportedPlatforms, value, listRange}} />
          </Box>
          <button
            className={clx('filter-clear', value !== '' || !(isEmpty(listRange.minListPrice) && isEmpty(listRange.maxListPrice) && 'hide'))}
            onClick={() => {
              if (showAll(filterBy)) {
                setValue('all')
              } else {
                resetFilterValue()
              }
            }}
          >
            <CloseIcon />
          </button>
        </Box>
        <Box className="apply-filter-btn">
          <Button
            className={clx('btn-header', 'secondary', 'apply-filter-btn-cancel')}
            onClick={() => {
              resetFilterValue()
              onSaveChanges({filter: {}})
              setAnchorEl(null)
            }}
          >
            Cancel
          </Button>
          <Button id="#onApply" className={clx('btn-header', 'primary', 'apply-filter-btn-apply')} onClick={onApply}>
            Apply
          </Button>
        </Box>
      </StyledMenu>
    </div>
  )
})

export default FilterToolbar
