import React, {useLayoutEffect, useCallback, useState, useEffect} from 'react'
import {Redirect, useHistory} from 'react-router-dom'
import * as Sentry from '@sentry/react'
import clx from 'classnames'
import {Box, Grid, Typography, Paper, Button} from '@mui/material'
import {createTheme, ThemeProvider, styled} from '@mui/material/styles'
import {CheckCircle as CheckCircleIcon} from '@mui/icons-material'

import {apiConfig} from 'config'

import {StaticImage} from 'components/primitives'
import {useToastMessage} from 'components'
import {useRedirCallback} from 'hooks/use_redir_callback'
import {usePlatformMarket} from 'service/hook/use_market'
import {useCredential} from 'service/hook'
import './scss/parent.scss'

const State = {
  UNDEFINED: -1,
  LOADING: 0,
  SUCCESS: 1,
  ERROR: 2,
}

const Item = styled(Paper)(({theme}) => ({
  ...theme.typography.body2,
  textAlign: 'center',
  color: theme.palette.text.secondary,
  height: 60,
  lineHeight: '60px',
}))

const ShopifyBilling = () => {
  const {shopifyBillingSetup} = usePlatformMarket()
  const {credential} = useCredential()
  const [state, setState] = useState(State.UNDEFINED)
  const [paymentUrl, setPaymentUrl] = useState(null)
  const history = useHistory()
  const {showToast} = useToastMessage()
  const [data, setData] = useState({})
  const [hasShopifyPayment, setHasShopifyPayment] = useState(false)
  const {shopifyCallback, shopifySetup} = usePlatformMarket()

  useLayoutEffect(() => {
    document.body.style.backgroundColor = 'var(--success)'
  })

  const urlParams = new URLSearchParams(history?.location?.search)
  const params = Object.fromEntries(urlParams)

  useEffect(() => {
    if (credential?.data?.length > 0 && !hasShopifyPayment) {
      const shopifyCredential = credential?.data?.find(
        (c) => c?.platform?.name.toLowerCase() === 'shopify',
      )

      if (shopifyCredential) {
        const {shopify_billing_status, shopifyToken, shopId} = shopifyCredential.value || {}

        if (!shopify_billing_status) {
          setData({access_token: shopifyToken, shopId})
        }

        setHasShopifyPayment(true)
      }

      setHasShopifyPayment(true)
    } else if (Object.keys(data)?.length === 0) {
      setData({data})
    }
  }, [data, credential])

  const process = useCallback(async () => {
    if (Object.keys(params)?.length > 0) {
      try {
        setState(State.LOADING)
        const data = await shopifyCallback(history.location.search)
        setData({...data})

        const setupResult = await shopifySetup({
          shopId: urlParams.get('shop'),
          shopifyToken: data.access_token,
          scope: data.scope,
          api: `${apiConfig.api_url}/v2/api`,
          shopify_billing_status: false,
        })
        if (!setupResult) {
          throw new Error('Faild to setup credentails.')
        }
        setState(State.UNDEFINED)
      } catch (e) {
        showToast({
          variant: 'danger',
          title: 'Failed',
          body: 'Failure to process request! Please re-authenticate. Redirecting to Shopify.',
        })
        setTimeout(() => {
          setState(State.ERROR)
        }, 1000)
        Sentry.captureException(e)
        throw e.message
      }
    }
  }, [history, shopifyCallback, setState])

  useRedirCallback(process)

  const onConfirm = useCallback(async () => {
    setState(State.LOADING)
    try {
      const billing = Object.assign(
        {},
        {
          name: 'Monthly Payment',
          shopifyToken: data?.access_token,
          shopId: params?.shop || data?.shopId,
          return_url: window.location.origin + '/admin/dashboard',
        },
      )

      const billingSetup = await shopifyBillingSetup(billing)
      setPaymentUrl(billingSetup?.confirmation_url)
      showToast({variant: 'success', title: 'Success', body: 'Redirecting to Shopify Billing Page...'})
      setTimeout(() => {
        setState(State.SUCCESS)
      }, 500)
    } catch (e) {
      showToast({
        variant: 'danger',
        title: 'Failed',
        body: 'Failure to process payment request! Please re-authenticate. Redirecting to Shopify.',
      })
      setTimeout(() => {
        setState(State.ERROR)
      }, 3000)
      Sentry.captureException(e)
      throw e.message
    }
  }, [shopifyBillingSetup, setState, data])

  let btn = !state ? 'Please Wait' : 'Continue'

  if (state === State?.SUCCESS) {
    window.location.href = paymentUrl
  } else if (state === State?.ERROR) {
    return <Redirect to="admin/platforms/2fd1c2df-c8e8-455a-824f-8ef167eb3caa" />
  }

  return (
    <div className="shopify-billing-container">
      <Typography sx={{color: '#fff'}}>Copyt Enterprise</Typography>
      <Grid container className="grid-container">
        <Grid item className="grid-item">
          <ThemeProvider theme={createTheme({palette: {mode: 'light'}})}>
            <Box sx={{width: '50%'}}>
              <Typography className="ready-to" variant="h5">
                Ready to automate your resale business?
              </Typography>
              <Item
                elevation={12}
                sx={{
                  height: 'auto',
                  mt: 2,
                  pb: 4,
                }}
              >
                <StaticImage
                  src="https://i0.wp.com/copyt.io/wp-content/uploads/2022/02/copyt-8.png"
                  width={280}
                />
                <Box
                  sx={{
                    pl: 3,
                    textAlign: 'left',
                  }}
                >
                  <Typography className="shopify">Shopify Integration</Typography>
                  <Typography className="price">
                    $50 <span>/month</span>
                  </Typography>
                </Box>
                <Button
                  className={clx(!state && 'shopify-continue-btn')}
                  sx={{color: '#fff', backgroundColor: '#d5239e', borderColor: '#d5239e'}}
                  disabled={!state}
                  size="small"
                  variant="contained"
                  onClick={() => onConfirm()}
                >
                  {btn}
                </Button>
                <div className="feature">
                  <Typography className="title">Features</Typography>
                  <div className="feature-item">
                    <CheckCircleIcon color="primary" />
                    <Typography className="subtitle">Individual and bulk listing to Shopify</Typography>
                  </div>
                  <div className="feature-item">
                    <CheckCircleIcon />
                    <Typography className="subtitle">Automatic Shopify sale detection</Typography>
                  </div>
                  <div className="feature-item">
                    <CheckCircleIcon />
                    <Typography className="subtitle">
                      Consignment functionality with built-in consignor portal synced with Shopify
                    </Typography>
                  </div>
                  <div className="feature-item">
                    <CheckCircleIcon />
                    <Typography className="subtitle">
                      Robust sneaker database to accelerate inventory management and listing processes to
                      Shopify
                    </Typography>
                  </div>
                </div>
              </Item>
            </Box>
          </ThemeProvider>
        </Grid>
      </Grid>
    </div>
  )
}

export default ShopifyBilling
