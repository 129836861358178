import React, {useEffect, useState} from 'react'
import {Box, Chip, Stack, TextField, Typography} from '@mui/material'
import styled from 'styled-components'
import {isEmpty} from 'lodash'

import {CopytButton, CopytLabel} from 'views_v2/lib/snippets'
import {ModalContainer, ModalHeading, ModalBody, ModalFooter} from 'views_v2/lib/snippets/CopytModal'
import {category_options} from 'assets/data'

export const CopytBtn = styled(CopytButton)({
  width: '30%',
  height: 35,
  borderRadius: 6,
})

const StyledTextField = styled(TextField)({
  '& .MuiInputBase-root': {
    width: '100%',
  },
  '& .MuiFilledInput-root': {
    paddingLeft: '10px',
    paddingRight: '10px',
  },
  '&': {
    flex: 1,
  },
})

const MdlFilterBy = (p) => {
  const {count, onSaveChanges} = p
  const [filter, setFilter] = useState({})

  const handlePressApply = () => {
    const _category = !isEmpty(filter?.category) && filter?.category?.length <= 5 ? filter?.category?.join(',') : ''
    const _status = !isEmpty(filter?.status) && filter?.status?.length === 1 ? filter.status.join(',') : ''

    onSaveChanges({
      filter: {
        ...filter,
        category: _category ?? '',
        status: _status?.toLowerCase() ?? '',
      },
    })
  }

  useEffect(() => {
    if (!p?.filter) return

    const {name, location, category, status} = p.filter

    setFilter({
      name: name ?? '',
      location: location ?? '',
      category: category ? category.split(',') : [],
      status: status ? status.split(',') : [],
    })
  }, [p?.filter])

  return (
    <ModalContainer>
      <Box className="modal-title">
        <ModalHeading className="modal__heading">
          <Typography variant="h1">Apply Filter</Typography>
        </ModalHeading>
      </Box>
      <ModalBody style={{marginBottom: 40}}>
        <Stack alignItems="center" gap={2} px={2} width="100%">
          <Stack direction="row" alignItems="center" width="100%" justifyContent="flex-start">
            <Box minWidth={100}>
              <CopytLabel>Item Name</CopytLabel>
            </Box>
            <StyledTextField
              hiddenLabel
              value={filter?.name ?? ''}
              variant="filled"
              size="small"
              onChange={(e) => setFilter({...filter, name: e.target.value})}
              onKeyDown={(e) => e.stopPropagation()}
            />
          </Stack>
          <Stack direction="row" alignItems="center" width="100%" justifyContent="flex-start">
            <Box minWidth={100}>
              <CopytLabel>Category</CopytLabel>
            </Box>
            <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 1}}>
              {category_options.map((_category) => {
                const isSelected = filter?.category?.includes(_category?.value)
                return (
                  <Chip
                    key={_category.value}
                    label={_category.label}
                    size="small"
                    onClick={() => {
                      if (!isEmpty(filter?.category)) {
                        setFilter((prev) => {
                          const exists = prev?.category?.includes(_category?.value)
                          return {
                            ...prev,
                            category: exists
                              ? prev.category.filter((_prevCategory) => _prevCategory !== _category?.value)
                              : [...prev.category, _category?.value],
                          }
                        })
                      } else {
                        setFilter({...filter, category: [_category?.value]})
                      }
                    }}
                    sx={{
                      '& .MuiChip-label': {
                        border: '1px solid var(--gray)',
                        borderRadius: 6,
                        backgroundColor: isSelected ? 'var(--gray)' : 'white',
                        color: isSelected ? 'white' : 'var(--default)',
                      },
                    }}
                  />
                )
              })}
            </Box>
          </Stack>
          <Stack direction="row" alignItems="center" width="100%" justifyContent="flex-start">
            <Box minWidth={100}>
              <CopytLabel>Status</CopytLabel>
            </Box>
            <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 0.5}}>
              {['unlisted', 'listed'].map((value) => {
                const isSelected = filter?.status?.includes(value)
                return (
                  <Chip
                    key={value}
                    label={value}
                    size="small"
                    onClick={() => {
                      if (!isEmpty(filter?.status)) {
                        setFilter((prev) => {
                          const exists = prev?.status?.includes(value)
                          return {
                            ...prev,
                            status: exists ? prev.status.filter((s) => s !== value) : [...prev.status, value],
                          }
                        })
                      } else {
                        setFilter({...filter, status: [value]})
                      }
                    }}
                    sx={{
                      '& .MuiChip-label': {
                        border: '1px solid var(--gray)',
                        borderRadius: 6,
                        backgroundColor: isSelected ? 'var(--gray)' : 'white',
                        color: isSelected ? 'white' : 'var(--default)',
                        textTransform: 'capitalize',
                      },
                    }}
                  />
                )
              })}
            </Box>
          </Stack>
          <Stack direction="row" alignItems="center" width="100%" justifyContent="flex-start">
            <Box minWidth={100}>
              <CopytLabel>Location</CopytLabel>
            </Box>
            <StyledTextField
              hiddenLabel
              value={filter?.location ?? ''}
              variant="filled"
              size="small"
              onChange={(e) => setFilter({...filter, location: e.target.value})}
              onKeyDown={(e) => e.stopPropagation()}
            />
          </Stack>
        </Stack>
      </ModalBody>

      <ModalFooter mb={2}>
        <CopytBtn
          variant="outline"
          color="primary"
          onClick={() =>
            onSaveChanges({
              filter: {
                name: '',
                location: '',
                status: '',
                category: '',
              },
            })
          }
        >
          Reset
        </CopytBtn>
        <CopytBtn variant="contained" color="primary" onClick={handlePressApply}>
          Apply
        </CopytBtn>
      </ModalFooter>
    </ModalContainer>
  )
}

export default MdlFilterBy
