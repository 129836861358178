import React, {useState, useEffect} from 'react'
import axios from 'axios'
import {useAuth0} from '@auth0/auth0-react'
import {Box, Stack, Typography} from '@mui/material'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import {styled} from '@mui/material/styles'
import {isEmpty} from 'lodash'

import {paths} from 'constant/api'
import {ConsignStatus, ToastType} from 'enums'
import useEnterKeyListener from 'hooks/useEnterKeyListener'
import {useItem} from 'service/hook/useItem'
import {CopytStyledButton} from 'views_v2/lib/snippets'

const Text = styled(Typography)(() => ({
  color: '#535f7f !important',
  lineHeight: 1.3,
}))

const MdlDeleteAll = (p) => {
  const {getAccessTokenSilently} = useAuth0()
  const {getUnconsignedUnlistedCount} = useItem()
  const [loading, setLoading] = useState(false)
  const [totalItems, setTotalItems] = useState(false)
  const [selectedConsignItem, setSelectedConsignedItem] = useState({})
  const {onCloseModal, listSelections, setToastType, setDeletionCount, setListSelections} = p

  const onFormSubmit = async () => {
    setLoading(true)
    try {
      const token = await getAccessTokenSilently()
      const response = await axios.delete(`${paths.product}/deleteAllUnlisted`, {
        data: {items: listSelections.length > 0 ? listSelections : 0},
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      if (response?.status === 200) {
        setToastType(ToastType.DELETE_ALL_SUCCESS)
        setDeletionCount(response.data.result.success?.affected)
        setTimeout(() => {
          setListSelections([])
          setSelectedConsignedItem({})
        }, 500)
      } else {
        setToastType(ToastType.DELETE_ALL_FAILURE)
      }
    } catch (e) {
      setToastType(ToastType.DELETE_ALL_ERROR)
    }

    setLoading(false)
    onCloseModal()
  }

  useEnterKeyListener({
    querySelectorToExecuteClick: '#onFormSubmit',
  })

  useEffect(() => {
    ;(async () => {
      if (!totalItems) {
        setTotalItems(await getUnconsignedUnlistedCount())
      }
    })()
  }, [getUnconsignedUnlistedCount])

  useEffect(() => {
    if (!isEmpty(listSelections)) {
      const consignedItemExist = listSelections?.find((item) => item.consign !== null)
      setSelectedConsignedItem(consignedItemExist)
    }
  }, [listSelections])

  const isConsignedOrWithdrawItems =
    selectedConsignItem?.consign && selectedConsignItem?.consign?.status !== ConsignStatus.WITHDRAW_APPROVED

  return (
    <Box padding="0 !important">
      <Box className="modal-title">
        <Box className="modal__heading" mx="0px !important">
          <Typography variant="h1">Confirm Action</Typography>
        </Box>
      </Box>
      <Box className="modal-body" padding={0} my={2}>
        <Stack direction="row" spacing={1} ml={1} mr={3}>
          <DeleteForeverIcon sx={{fontSize: '4rem !important'}} />
          <Stack spacing={1}>
            {isConsignedOrWithdrawItems ? (
              <Text>
                Please select items that are not on consignment or have been approved for withdrawal, as other consigned items cannot be
                removed.
              </Text>
            ) : listSelections?.length > 0 ? (
              <Text>
                Are you sure you want to delete <Text component="span">{listSelections.length}</Text> of your selected NON-CONSIGNED items
                or items approved for withdrawal?
              </Text>
            ) : (
              <Text>Are you sure you want to delete all {totalItems} of your NON-CONSIGNED items or items approved for withdrawal?</Text>
            )}
            <Text fontWeight="600 !important" fontStyle="italic" fontSize="0.9rem">
              Warning! This action cannot be undone.
            </Text>
          </Stack>
        </Stack>

        <Stack direction="row" justifyContent="flex-end" pr={1} pt={1} mt={5} borderTop="1px solid #eee" spacing={2}>
          <CopytStyledButton variant="outline" color="primary" onClick={() => onCloseModal()}>
            Cancel
          </CopytStyledButton>
          <CopytStyledButton
            disabled={loading || isConsignedOrWithdrawItems}
            variant="contained"
            color="error"
            onClick={onFormSubmit}
            sx={{width: '30%'}}
          >
            {loading ? 'Please Wait...' : 'Yes, Delete'}
          </CopytStyledButton>
        </Stack>
      </Box>
    </Box>
  )
}

export default MdlDeleteAll
