import React, {useState} from 'react'
import {Box, Checkbox, FormControlLabel, Stack, Typography} from '@mui/material'
import {GppGood as GppGoodIcon} from '@mui/icons-material'

import {CopytStyledButton} from 'views_v2/lib/snippets'
import {ModalContainer, ModalHeading, ModalBody, ModalFooter} from 'views_v2/lib/snippets/CopytModal'

const MdlAuditFinish = (p) => {
  const {count, onSaveChanges} = p
  const [isChecked, setIsChecked] = useState(false)

  return (
    <ModalContainer>
      <Box className="modal-title">
        <ModalHeading className="modal__heading">
          <Typography variant="h1">Success</Typography>
        </ModalHeading>
      </Box>
      <ModalBody>
        <Stack direction="row" px={2} spacing={2} alignItems="center">
          <GppGoodIcon sx={{fontSize: '4rem !important', color: 'green'}} />
          <Stack direction="column" spacing={2}>
            <Box>
              <Typography color="var(--gray-dark) !important">
                Total Verified Items:&nbsp;
                <Box component="span" fontWeight={600}>
                  {count?.verified?.toLocaleString()}
                </Box>
              </Typography>
              <Typography color="var(--gray-dark) !important">
                Remaining Unverified Items:&nbsp;
                <Box component="span" fontWeight={600}>
                  {count?.unverified?.toLocaleString()}
                </Box>
              </Typography>
            </Box>

            <FormControlLabel
              control={<Checkbox color="secondary" checked={isChecked} onChange={(e) => setIsChecked(e.target.checked)} />}
              label={
                <Stack direction="column" ml={1}>
                  <Typography lineHeight="14px" fontSize="14px" sx={{fontWeight: 600}}>
                    Delist Unverified Items?
                  </Typography>
                  <Typography fontSize="12px" sx={{lineHeight: '14px', color: 'red !important', fontStyle: 'italic'}}>
                    Warning! This action cannot be undone.
                  </Typography>
                </Stack>
              }
              sx={{width: '100%', marginLeft: 5, marginRight: 2, borderTop: 1, borderColor: 'var(--lighter) !important', pt: 2}}
            />
          </Stack>
        </Stack>
      </ModalBody>
      <ModalFooter mb={1} mt={0}>
        <CopytStyledButton variant="contained" color="primary" onClick={() => onSaveChanges({onVerified: true, isDelist: isChecked})}>
          Submit
        </CopytStyledButton>
      </ModalFooter>
    </ModalContainer>
  )
}

export default MdlAuditFinish
