import React from 'react'
import clx from 'classnames'
import {Button} from 'reactstrap'
import {GppMaybe as GppMaybeIcon} from '@mui/icons-material'
import useEnterKeyListener from 'hooks/useEnterKeyListener'

const MdlProceed = (p) => {
  const {item, onCloseModal} = p || {}

  const onFormSubmit = () => {
    p.onSaveChanges({onProceed: 'onProceed'})
  }

  useEnterKeyListener({
    querySelectorToExecuteClick: '#onFormSubmit',
  })

  return (
    <div>
      <div className="modal-title">
        <div className="modal__heading">
          <h1 className="">Confirm Action</h1>
        </div>
      </div>
      <div className="modal-body">
        <div className="audit-section">
          <div className="verification text-center">
            Are you sure you want to verify this item?
            <div className="item-description">
              <GppMaybeIcon />
              <div>
                <div>
                  <strong>{item?.title}</strong>
                </div>
                <div className="item-description-info">
                  <div>
                    <strong>Size:&nbsp;</strong>
                    {item?.size || '--'}
                  </div>
                  <div>
                    <strong>Internal Sku:&nbsp;</strong>
                    {item?.internalSku || '--'}
                  </div>
                </div>
              </div>
            </div>
            <div className="warning">Warning! This action cannot be undone.</div>
          </div>
        </div>
        <div className="delete-btn">
          <Button
            className={clx('btn-header', 'secondary', 'submit-consignment')}
            onClick={() => {
              onCloseModal()
            }}
          >
            Cancel
          </Button>
          <Button id="onFormSubmit" className={clx('btn-header', 'primary')} onClick={onFormSubmit}>
            Proceed
          </Button>
        </div>
      </div>
    </div>
  )
}

export default MdlProceed
