import React, {useCallback, useContext, useEffect, useState} from 'react'
import clx from 'classnames'
import {DataGrid, GRID_CHECKBOX_SELECTION_COL_DEF} from '@mui/x-data-grid'
import {Avatar, Box, Checkbox, Chip, Grid, Tooltip} from '@mui/material'
import {HourglassBottom, Report} from '@mui/icons-material'
import {debounce, difference, first, isEmpty, sumBy, unionBy} from 'lodash'

import DetailView from 'components/DetailView'
import {ListedTableFilterMenu} from 'assets/data/options/inventory_filter_menu'
import {ToastType, RedirectAction} from 'enums'
import {ListingPlatformStatus} from 'constant/listing_platform_status'
import {ListingStatus} from 'constant/listing_status'
import {useUser} from 'service/hook'
import {useGlobalStore} from 'provider/global_store/hook'
import {getCurrency} from 'util/model/setting'
import {isConsigned, forRemove, getListingStatus} from 'util/model/product'
import {getLogo} from 'util/model/platform'
import DateUtils from 'util/date_utils'
import {capitalizeText, _getValue} from 'util/string_utils'
import {CopytTooltip} from 'views_v2/lib/snippets'
import {ConsignorProfile} from 'views_v2/modules/Consignments/component/consignment_application/ConsignorProfile'

import {ReactComponent as ConsignedBannerSvg} from '../../../../../../assets/img/consigned-banner_v2.svg'
import {generateExportData, getListingStatusProps} from '../../../hooks/util'
import {CustomFooter, EmptyInventory, InventoryToolbar} from '../../../snippets'
import {SearchContext, TabPanel} from '../../../index'
import InventoryToast from '../../InventoryToast'

const ListedTable = (p) => {
  const {
    changeCheckbox,
    disableBtn,
    handleChange,
    items,
    routeChange,
    isFetching,
    queries,
    listSelections,
    currentPage,
    setCurrentPage,
    showWithdraw,
    isCardList = false,
    onNavigateUrl,
    showDelete,
    isLoading,
    metricsQuery,
    searchKey,
  } = p
  const {user} = useUser()
  const {isEnterprise} = useGlobalStore()
  const currency = getCurrency()
  const isMobile = window.innerWidth <= 800
  const [toastType, setToastType] = useState(ToastType.UNDEFINED)
  const search = useContext(SearchContext)
  const onSearch = useCallback(
    debounce((searchKey) => {
      search.setListedSearchText(searchKey), 1000
    }),
    [],
  )

  const columns = [
    {
      field: 'assets',
      headerName: '',
      flex: isEnterprise ? 1 : 0.7,
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        const {assets} = params.row || {}
        const hasConsignBanner = isConsigned(params.row)
        const hasNoImage = assets?.length === 0
        const image = hasNoImage
          ? null
          : assets.reduce((a, b) => {
              return Math.abs(b.order - 1) < Math.abs(a.order - 1) ? b : a
            })

        return (
          <div className={clx(hasNoImage && hasConsignBanner && 'no-image', 'img-overlay-wrap')}>
            {hasConsignBanner && <ConsignedBannerSvg />}
            {!hasNoImage && <img src={image?.asset?.url} />}
          </div>
        )
      },
    },
    {
      field: 'title',
      headerName: 'Name',
      headerAlign: 'center',
      align: 'center',
      flex: 2,
      renderCell: (params) => {
        const title = params?.value
        return (
          <Tooltip {...{title}}>
            <span>{title}</span>
          </Tooltip>
        )
      },
    },
    {
      field: 'sku',
      headerName: 'SKU/Style ID',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      hide: isMobile,
    },
    {
      field: 'size',
      headerName: 'Size',
      headerAlign: 'center',
      align: 'center',
      flex: isEnterprise ? 1 : 0.7,
    },
    {
      field: 'condition',
      headerName: 'Condition',
      headerAlign: 'center',
      align: 'center',
      flex: isEnterprise ? 1 : 0.7,
    },
    {
      field: 'price',
      headerName: 'Buy Price',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      renderCell: (params) => {
        const {consign} = params?.row || {}
        if (consign && isEnterprise) return ''
        else return `${currency.format(params?.value)}`
      },
    },
    {
      field: 'desiredReturn',
      headerName: 'List Price',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      renderCell: (params) => `${currency.format(params?.value)}`,
    },
    {
      field: 'internalSku',
      headerName: 'Internal SKU',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      hide: !isEnterprise || isMobile,
    },
    {
      field: 'location',
      headerName: 'Location',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      hide: !isEnterprise || isMobile,
    },
    {
      field: 'subLocation',
      headerName: 'Sub Location',
      headerAlign: 'center',
      align: 'center',
      flex: 0.8,
      hide: !isEnterprise || isMobile,
    },
    {
      headerName: 'Shipping Status',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      hide: isMobile || isEnterprise,
      renderCell: (params) => {
        const {shipments} = params?.row || {}
        if (!shipments?.length) return

        shipments.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))

        const [{shipment}] = shipments

        let label = shipment?.status

        switch (shipment?.type) {
          case 'CONSIGNMENT': {
            switch (shipment?.status) {
              case 'TRANSIT': {
                if (isEnterprise) break
                label = 'RECEIVED'
                break
              }
            }
            break
          }
        }

        return (
          <CopytTooltip title={label}>
            <Chip label={capitalizeText(label)} size="small" />
          </CopytTooltip>
        )
      },
    },
    {
      field: 'owner',
      headerName: 'Owner',
      headerAlign: 'center',
      flex: 1.8,
      hide: !isEnterprise || isMobile,
      renderCell: (params) => {
        const {row} = params
        const hasConsignBanner = isConsigned(params.row)
        let email = ''
        let companyName = ''

        if (hasConsignBanner) {
          if (row?.consign?.consignor?.email) {
            email = row?.consign?.consignor?.email
          } else {
            email = row?.owner?.email
          }
        } else {
          companyName = user?.company?.name
          email = row?.owner?.email
        }

        if (hasConsignBanner) {
          return <ConsignorProfile consignorEmail={row?.consign?.consignor?.email} consignorId={row?.consign?.consignor?.id} />
        }

        return (
          <Tooltip title={email}>
            <Box component="span">{companyName}</Box>
            <Box component="span" sx={{color: 'var(--light)', fontSize: '0.7rem', lineHeight: '13px'}}>
              {email}
            </Box>
          </Tooltip>
        )
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      headerAlign: 'center',
      align: 'center',
      flex: 1.2,
      hide: isMobile,
      renderCell: (params) => {
        const item = params.row || {}

        if (!isEmpty(item?.listings)) {
          const listingPlatforms = first(item?.listings)?.listingPlatforms || []

          if (!isEmpty(listingPlatforms)) {
            listingPlatforms?.sort((a, b) => {
              const platformA = a?.platform?.name || ''
              const platformB = b?.platform?.name || ''
              return platformA.localeCompare(platformB)
            })

            return (
              <Grid container rowSpacing={0.4} columns={{xs: 4, sm: 8, md: 12}} display="flex" justifyContent="center" alignItems="center">
                {listingPlatforms?.map((lp, idx) => {
                  const avatarIcon = getLogo(lp?.platform)
                  const lpStatusColor = getListingStatusProps(lp?.status)

                  if (lp?.platform?.name === 'Paypal' || lp?.platform?.name === 'Checkbook') return null

                  const isConsignedItem = isConsigned(params.row)

                  const tooltipTitle = isConsignedItem ? `${lp?.platform?.name}: Consigned` : `${lp?.platform?.name}: ${lpStatusColor.text}`

                  return (
                    <Grid key={idx} item xs={6} sm={4} md={4} lg={3}>
                      <Tooltip title={tooltipTitle}>
                        <Avatar
                          alt="User Avatar"
                          src={avatarIcon}
                          sx={{
                            border: `3px solid ${lpStatusColor.color}`,
                            width: 30,
                            height: 30,
                          }}
                          imgProps={{
                            style: {
                              objectFit: 'contain',
                              filter: 'grayscale(100%)',
                              padding: 4,
                            },
                          }}
                        />
                      </Tooltip>
                    </Grid>
                  )
                })}
              </Grid>
            )
          }
        }

        return null
      },
    },
    {
      field: 'daysListed',
      headerName: 'Day(s) Listed',
      headerAlign: 'center',
      align: 'center',
      flex: 0.6,
      renderCell: (params) => {
        const {raw, days} = DateUtils.calculateDateDifference(params?.row?.listings)

        return (
          <CopytTooltip title={DateUtils.formatToLocalDate(raw)}>
            <Box component="span">{days}</Box>
          </CopytTooltip>
        )
      },
    },
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      flex: 0.2,
    },
  ]

  const modifiedCheckboxColumn = {
    ...GRID_CHECKBOX_SELECTION_COL_DEF,
    renderHeader: () => {
      return (
        <Checkbox
          indeterminate={!isEmpty(listSelections) && listSelections?.length !== items?.total}
          checked={listSelections?.length === items?.total}
          onChange={() => {
            if (isEmpty(listSelections)) {
              p?.onListSelections(items?.data)
              changeCheckbox()
            } else if (listSelections?.length === items?.data?.length && items?.data?.every((item) => listSelections?.includes(item))) {
              p?.onListSelections([])
              changeCheckbox()
            } else {
              p?.onShowModal(true)
            }
          }}
        />
      )
    },
    renderCell: (params) => {
      const {row} = params
      const ownerId = row?.owner?.id
      const productInternalSku = row?.internalSku
      const listings = first(row?.listings)
      const listingPlatforms = listings?.listingPlatforms

      const listingStatus = getListingStatus(row)
      const title =
        listingStatus === 'sold'
          ? 'Marking item as SOLD'
          : listingStatus === 'processed'
          ? 'Marking item as PROCESSED'
          : 'Delisting in progress'

      const failedReason =
        listings?.status === ListingStatus.FOR_REMOVE
          ? 'Item failed to delist'
          : listings?.status === ListingStatus.SOLD
          ? 'Item failed to mark as sold'
          : ''

      const failedToDelist = listingPlatforms?.every((lp) => lp?.status === ListingPlatformStatus.REMOVE_FAILED)

      if (failedToDelist) {
        const handleReportClick = () => {
          navigator.clipboard.writeText(`COPYT REPORT\n\nOwner ID: ${ownerId}\nProduct SKU: ${productInternalSku}\nReason: ${failedReason}`)
        }

        return (
          <CopytTooltip title={failedReason}>
            <Box component="span" onClick={handleReportClick}>
              <Report />
            </Box>
          </CopytTooltip>
        )
      }

      if (!!forRemove(params.row) && !DateUtils.calculateDateDifference(params.row.listings).isToOld) {
        return (
          <CopytTooltip title={title}>
            <HourglassBottom />
          </CopytTooltip>
        )
      } else {
        return GRID_CHECKBOX_SELECTION_COL_DEF.renderCell ? GRID_CHECKBOX_SELECTION_COL_DEF.renderCell(params) : <Checkbox />
      }
    },
    flex: 0.2,
  }

  const newColumns = columns.map((col) => (col.type === 'checkboxSelection' ? modifiedCheckboxColumn : col))

  const SearchToolBar = () => {
    if (!isCardList) {
      return (
        <InventoryToolbar
          {...{disableBtn, queries}}
          filterMenu={ListedTableFilterMenu}
          showAverage
          average={{
            cost: metricsQuery?.data?.averageCost || 0,
            price: metricsQuery?.data?.averagePrice || 0,
          }}
          search={{
            disabled: isFetching,
            value: search?.listedSearchText,
            onSearch: onSearch,
          }}
          action={{
            onNavigateUrl: onNavigateUrl,
            onSaveChanges: p?.onSaveChanges,
          }}
          allItems={generateExportData(p?.allData, TabPanel.LISTED)}
          selectedTab="Listed"
          showWithdraw={showWithdraw}
          showDelete={showDelete}
        />
      )
    }
    return null
  }

  const CustomNoRowsOverlay = () => {
    return <EmptyInventory {...{disableBtn, handleChange, routeChange}} type="listing" tabSelection="listed" />
  }

  const rows = isCardList ? p?.cardItem : items?.data

  const rowCount = isCardList ? rows?.length : items?.total || 0

  useEffect(() => {
    if (!isEmpty(rows) && !isEmpty(searchKey) && rows?.every((row) => row?.internalSku?.toLowerCase().includes(searchKey?.toLowerCase()))) {
      p?.onListSelections(unionBy(rows, listSelections, 'id'))
    }
  }, [rows, searchKey])

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search)
    const params = {}

    for (const [key, value] of searchParams.entries()) {
      params[key] = value
    }
    search.setListedSearchText(params[RedirectAction.SEARCH]?.split(' ').join('+'))
    searchParams.delete(RedirectAction.SEARCH)

    const newUrl = window.location.pathname + '?' + searchParams.toString()
    window.history.replaceState({}, '', newUrl)
  }, [])

  return (
    <DetailView.Panel style={{height: isCardList ? '500px' : '100%'}}>
      <Box className="inventory-container listed-table">
        <DataGrid
          rows={rows || []}
          columns={newColumns || []}
          checkboxSelection
          disableColumnSelector
          disableColumnMenu
          disableDensitySelector
          disableVirtualization
          loading={isLoading || isFetching}
          isRowSelectable={(params) => !forRemove(params?.row) || DateUtils.calculateDateDifference(params.row.listings).isToOld}
          selectionModel={Array.isArray(listSelections) && listSelections?.map((s) => s?.id)}
          onCellClick={(param) => {
            if (param?.field === '__check__') {
              p?.onListSelections((prevSelections) => {
                const alreadySelected = prevSelections?.some((item) => item?.id === param?.row?.id)

                const updatedSelections = alreadySelected
                  ? prevSelections?.filter((item) => item?.id !== param.row.id)
                  : [...prevSelections, param.row]

                const isSelectedRowsConsigned = updatedSelections.some((data) => isConsigned(data))

                changeCheckbox(isSelectedRowsConsigned)

                return updatedSelections
              })
            } else {
              const {listings} = param?.row
              if (listings?.length > 0) {
                routeChange(`/admin/listings/viewListing/${listings[0]?.id}`)
              }
            }
          }}
          components={{
            Toolbar: SearchToolBar,
            NoRowsOverlay: CustomNoRowsOverlay,
            Footer: CustomFooter,
          }}
          componentsProps={{
            isCardList,
            footer: {
              total: [
                (isCardList ? sumBy(rows, (product) => parseFloat(product?.desiredReturn)) : metricsQuery?.data?.totalListPrice) || 0,
                metricsQuery?.data?.totalEstimatedPayout || 0,
              ],
              label: ['List Price', 'Estimated Payout'],
              tooltip: [null, 'This calculation is based only on consigned items'],
              otherFooters: [
                {
                  value: _getValue(metricsQuery?.data?.totalCost),
                  label: 'Total Cost',
                },
              ],
            },
          }}
          getRowId={(row) => row.id}
          rowCount={rowCount}
          getRowClassName={() => 'datagrid-row'}
          onPageChange={(newPage) => {
            if (!isLoading && !isFetching && !isCardList) {
              setCurrentPage(newPage)
            }
          }}
          page={currentPage}
          pageSize={100}
          pagination
          paginationMode="server"
          disablePagination={isLoading || isFetching}
          rowsPerPageOptions={[100]}
          onSortModelChange={(newSort) => (isEmpty(rows) ? null : p?.onSaveChanges({sort: newSort[0]}))}
          sx={{
            '& .MuiDataGrid-main': {
              bgcolor: 'white',
            },
            '& .MuiDataGrid-columnHeaders, & .MuiDataGrid-footer': {
              bgcolor: '#faebf5',
            },
            '& .MuiTablePagination-actions': {
              button: {
                opacity: isLoading || isFetching ? 0.3 : 1,
                cursor: isLoading || isFetching ? 'no-drop' : 'pointer',
                pointerEvents: isLoading || isFetching ? 'none' : '',
              },
              '& .Mui-disabled': {
                opacity: 0.3,
              },
            },
          }}
        />
      </Box>
      {toastType > ToastType.UNDEFINED && <InventoryToast {...{toastType, setToastType}} />}
    </DetailView.Panel>
  )
}

export default ListedTable
