import React, {useReducer} from 'react'
import {Box, Stack, Typography} from '@mui/material'
import styled from 'styled-components'

import {ModalContainer, ModalBody, ModalHeading, ModalFooter} from 'views_v2/lib/snippets/CopytModal'
import {CopytButton} from 'views_v2/lib/snippets'
import {checkObjIfEmpty} from 'util/string_utils'
import ArrayUtils from 'util/array_utils'
import {DuplicateItem} from '../../snippets'

const CopytBtn = styled(CopytButton)({
  width: '80%',
  height: 35,
  borderRadius: 6,
  textTransform: 'capitalize',
})

const MdlDuplicateItem = (p) => {
  const {loading, onHide, onAddSize, onSubmit, prices, formFields, setDuplicateSizes, duplicateSizes} = p
  const [, forceUpdate] = useReducer((x) => x + 1, 0)
  const entry = ArrayUtils.last(duplicateSizes)
  const disabled = !checkObjIfEmpty(entry)

  const setDeleteItem = (i) => {
    duplicateSizes.splice(i, 1)
    setDuplicateSizes(duplicateSizes)
    forceUpdate()
  }

  return (
    <ModalContainer>
      <Box className="modal-title">
        <ModalHeading className="modal__heading">
          <Typography variant="h1">Duplicate Item</Typography>
        </ModalHeading>
      </Box>
      <ModalBody style={{marginBottom: 40}}>
        <Stack direction="column" alignItems="center" gap={2} px={2}>
          <Box className="text-center" pb={2} lineHeight={1.2}>
            Please complete all fields marked with * and click on the "Submit" button to duplicate your product.
          </Box>

          {duplicateSizes.map((value, index) => (
            <DuplicateItem
              {...{prices, formFields, setDuplicateSizes, index, setDeleteItem}}
              key={index}
              defaultValue={value}
              showDelete={duplicateSizes?.length > 1}
            />
          ))}
        </Stack>
      </ModalBody>
      <ModalFooter>
        <Stack direction="row" gap={1} flex={1} pl={1} pb={1}>
          <CopytBtn variant="outline" onClick={onHide}>
            Cancel
          </CopytBtn>
          <CopytBtn variant="contained" color="primary" disabled={!disabled || loading} onClick={onAddSize}>
            Add Size
          </CopytBtn>

          <CopytBtn variant="contained" disabled={!disabled || loading} onClick={onSubmit}>
            {loading ? 'Please Wait' : 'Submit'}
          </CopytBtn>
        </Stack>
      </ModalFooter>
    </ModalContainer>
  )
}

export default MdlDuplicateItem
