import React from 'react'
import {useHistory} from 'react-router-dom'
import {styled, alpha} from '@mui/material/styles'
import {Button, Menu, MenuItem, Divider} from '@mui/material'
import {
  Delete as DeleteIcon,
  FileUpload as FileUploadIcon,
  List as ListIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
  FileDownload,
  AutoFixHigh,
  SettingsBackupRestore,
} from '@mui/icons-material'
import {isEmpty, toLower} from 'lodash'

import {useGlobalStore} from 'provider/global_store/hook'
import {CopytTooltip} from 'views_v2/lib/snippets'
import {InventoryQueryType} from 'constant/query_type'

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({theme}) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      padding: '0 5px',
      margin: 3,
      display: 'flex',
      justifyContent: 'flex-start',
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}))

export const OptionsToolbar = (p) => {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const {hasMop} = useGlobalStore()
  const open = Boolean(anchorEl)
  const history = useHistory()

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const {
    showDelete,
    showListAll,
    showImport,
    showWithdraw,
    showBulkEdit,
    disableBtn,
    action,
    allItems,
    onSaveChanges,
    selectedRowIds,
    selectedTab,
  } = p

  const isShowMenu = [InventoryQueryType.SOLD, InventoryQueryType.PROCESSED].includes(
    toLower(selectedTab),
  )

  return (
    <div className="display-flex">
      <Button
        id="customized-button"
        className="options-btn"
        aria-controls={open ? 'customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
        disabled={isEmpty(allItems)}
      >
        Options
      </Button>

      <StyledMenu
        id="customized-menu"
        MenuListProps={{
          'aria-labelledby': 'customized-button',
          className: 'options-list',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {!isShowMenu && action?.onListMultiple && (
          <CopytTooltip
            title={
              !hasMop ? (
                <div onClick={() => history.push('/admin/settings/account')}>
                  Add a payment method in your Settings to list.
                </div>
              ) : !showListAll ? (
                <span>Please select an item first.</span>
              ) : (
                ''
              )
            }
            placement="top"
          >
            <span>
              <MenuItem
                disabled={(disableBtn && !hasMop) || !showListAll}
                onClick={action.onListMultiple}
              >
                <ListIcon />
                List Multiple
              </MenuItem>
            </span>
          </CopytTooltip>
        )}

        {selectedTab === 'Sold' && (
          <CopytTooltip
            title={!selectedRowIds?.length ? <span>Please select an item first.</span> : null}
            placement="top"
          >
            <span>
              <MenuItem onClick={action?.onProcessMultiple} disabled={!selectedRowIds?.length}>
                <ListIcon />
                Process Multiple
              </MenuItem>
            </span>
          </CopytTooltip>
        )}

        {showImport && (
          <span>
            <MenuItem onClick={() => history.push('/admin/inventory/import/-1')}>
              <FileUploadIcon />
              Import
            </MenuItem>
          </span>
        )}
        {!isShowMenu && (
          <CopytTooltip
            title={!showWithdraw ? <span>Please select a CONSIGNED item first.</span> : ''}
            placement="right"
          >
            <span>
              <MenuItem onClick={() => action.onSaveChanges({withdraw: true})} disabled={!showWithdraw}>
                <SettingsBackupRestore />
                Withdraw
              </MenuItem>
            </span>
          </CopytTooltip>
        )}

        {!isShowMenu && action?.onBulkEdit && (
          <CopytTooltip
            title={!showBulkEdit ? <span>Please select an item first.</span> : ''}
            placement="right"
          >
            <span>
              <MenuItem onClick={action.onBulkEdit} disabled={!showBulkEdit}>
                <AutoFixHigh />
                Bulk Edit
              </MenuItem>
            </span>
          </CopytTooltip>
        )}

        <span>
          {!isEmpty(allItems) && (
            <MenuItem
              disabled={isEmpty(allItems)}
              onClick={() => action.onSaveChanges({onExport: true})}
            >
              <FileDownload />
              Export
            </MenuItem>
          )}
        </span>
        {showDelete && (
          <span>
            <Divider sx={{my: 0.5}} />
            <MenuItem onClick={action.onDeleteAll}>
              <DeleteIcon />
              Delete
            </MenuItem>
          </span>
        )}
      </StyledMenu>
    </div>
  )
}

export default OptionsToolbar
