import {useState, useEffect} from 'react'
import {useMutation, useQuery} from 'react-query'
import axios from 'axios'
import {useAuth0} from '@auth0/auth0-react'
import {captureException} from '@sentry/react'

import {paths} from 'constant/api'
import {useToastMessage} from 'components'
import {createRequestOptions, getMessage} from 'util/network'
import {ar} from 'variables/empty'

const getLink = async (tokenGetter) => {
  try {
    const token = await tokenGetter()
    const response = await axios.get(paths.link, createRequestOptions(token))
    return response.data
  } catch (e) {
    captureException(e)
    console.log('Error retrieving link', e)
    throw e.message
  }
}

export const useLink = () => {
  const {getAccessTokenSilently} = useAuth0()
  const {showToast} = useToastMessage()
  const [fetchEnabled, setFetchEnabled] = useState(true)

  const {isLoading, isError, isFetched, refetch, data, error} = useQuery(
    'link',
    () => getLink(getAccessTokenSilently),
    {
      enabled: fetchEnabled,
      keepPreviousData: true,
    },
  )

  const claim = useMutation(
    async ({code}) => {
      const token = await getAccessTokenSilently()
      const response = await axios.post(paths.link + '/claim', {code}, createRequestOptions(token))
      return response.data
    },
    {
      onSuccess: async (data, variables, context) => {
        showToast({variant: 'success', title: 'Success', body: 'Items has been claimed'})
        setFetchEnabled(true)
        refetch()
      },
      onError: (req) => {
        showToast({
          variant: 'danger',
          title: 'Failed',
          body: getMessage(req) || 'Failed to claim',
        })
      },
    },
  )

  useEffect(() => {
    if (fetchEnabled) {
      refetch()
      setFetchEnabled(false)
    }
  }, [fetchEnabled, refetch])

  return {
    isLoading,
    isError,
    isFetched,
    refetchLink: () => setFetchEnabled(true),
    error,
    link: data?.data || ar,
    onClaim: claim.mutateAsync,
    onClaimIsLoading: claim.isLoading
  }
}

export default useLink
