import React, {memo, useState} from 'react'
import clx from 'classnames'
import {DataGrid} from '@mui/x-data-grid'
import {Box, Tooltip, Stack, Grid} from '@mui/material'
import {FileDownload} from '@mui/icons-material'
import SearchBar from 'material-ui-search-bar'
import {isEmpty} from 'lodash'

import DetailView from 'components/DetailView'
import {useGlobalStore} from 'provider/global_store/hook'
import {ReactComponent as ConsignedBannerSvg} from '../../../../../assets/img/consigned-banner_v2.svg'
import {getCurrency} from 'util/model/setting'
import {isConsigned} from 'util/model/product'
import {CopytTooltip, CopytButton, EmptyData} from 'views_v2/lib/snippets'
import {ConsignStatus, ModalType} from 'enums'

export const getAvg = (obj, key) => {
  return obj
    .map((item) => item[key])
    .reduce((a, b) => {
      return a + Number(b)
    }, 0)
}

const AuditTable = (p) => {
  let {currentPage, filter, items, isLoading, isFetching, setCurrentPage, setSearch, tabPanel, total, onSaveChanges} = p
  const {isEnterprise} = useGlobalStore()
  const currency = getCurrency()
  const [searchkey, setSearchKey] = useState('')

  const onSearch = () => {
    setSearch(searchkey)
    setSearchKey('')
  }

  const columns = [
    {
      field: 'assets',
      headerName: '',
      flex: isEnterprise ? 1 : 0.7,
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        const {assets, consign} = params.row || {}
        const hasConsignBanner =
          (isEnterprise && ['approved', 'withdraw_pending'].includes(consign?.status.toLowerCase())) ||
          (isConsigned(params.row) &&
            ![ConsignStatus.WITHDRAW_APPROVED, ConsignStatus.REJECTED, ConsignStatus.PENDING].includes(consign?.status.toLowerCase()))
        const hasNoImage = assets?.length === 0
        const image = hasNoImage
          ? null
          : assets.reduce((a, b) => {
              return Math.abs(b.order - 1) < Math.abs(a.order - 1) ? b : a
            })

        return (
          <div className={clx(hasNoImage && hasConsignBanner && 'no-image', 'img-overlay-wrap')}>
            {hasConsignBanner && <ConsignedBannerSvg />}
            {!hasNoImage && <img src={image?.asset?.url} />}
          </div>
        )
      },
    },
    {
      field: 'title',
      headerName: 'Item Name',
      headerAlign: 'center',
      flex: 2,
      renderCell: (params) => {
        const title = params?.value
        return (
          <Tooltip {...{title}}>
            <span>{title}</span>
          </Tooltip>
        )
      },
    },
    {
      field: 'sku',
      headerName: 'SKU/Style ID',
      headerAlign: 'center',
      align: 'center',
      flex: 1.1,
    },
    {
      field: 'size',
      headerName: 'Size',
      headerAlign: 'center',
      align: 'center',
      flex: 0.9,
    },
    {
      field: 'condition',
      headerName: 'Condition',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
    },
    {
      field: 'price',
      headerName: 'Buy Price',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      renderCell: (params) => {
        const {consign} = params?.row || {}
        if (consign && isEnterprise) return ''
        else return `${currency.format(params?.value)}`
      },
    },
    {
      field: 'desiredReturn',
      headerName: 'List Price',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      renderCell: (params) => {
        return `${currency.format(params?.row.desiredReturn)}`
      },
    },
    {
      field: 'internalSku',
      headerName: 'Internal SKU',
      headerAlign: 'center',
      align: 'center',
      flex: 1.1,
      hide: !isEnterprise,
      renderCell: (params) => {
        const title = params?.value
        const handleReportClick = () => {
          navigator.clipboard.writeText(params?.value)
        }
        return (
          <CopytTooltip title="Copy">
            <span onClick={handleReportClick}>{title}</span>
          </CopytTooltip>
        )
      },
    },
    {
      field: 'location',
      headerName: 'Location',
      headerAlign: 'center',
      align: 'center',
      flex: 1.1,
      hide: !isEnterprise,
      renderCell: (params) => {
        const title = params?.value
        return (
          <CopytTooltip title={title}>
            <span>{title}</span>
          </CopytTooltip>
        )
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      renderCell: (params) => {
        const item = params.row?.listings

        return isEmpty(item) ? 'Unlisted' : 'Listed'
      },
    },
  ]

  const SearchToolBar = () => {
    return (
      <Stack
        direction="row"
        borderBottom="1px solid var(--success)"
        alignItems="center"
        justifyContent="space-between"
        padding="8px 20px 8px 8px"
        sx={{
          background: '#f9ebf6',
        }}
      >
        {!tabPanel ? (
          <div>
            <Grid container gap={1}>
              <SearchBar
                autoFocus
                placeholder="Search Internal SKU"
                value={searchkey}
                className="search-item"
                cancelOnEscape
                onCancelSearch={onSearch}
                onRequestSearch={onSearch}
                onChange={setSearchKey}
              />
              <Grid item>
                <CopytButton disabled={isLoading || isFetching} onClick={() => p?.setModalType(ModalType.FILTER_BY)}>
                  Filter
                </CopytButton>
              </Grid>
            </Grid>
          </div>
        ) : (
          <Box />
        )}
        <CopytButton
          onClick={() => onSaveChanges({onExport: true})}
          style={{
            padding: '12px 0',
            height: '35px',
            color: '#93186d',
          }}
        >
          <FileDownload /> Export
        </CopytButton>
      </Stack>
    )
  }

  const NoData = () => <EmptyData title={`No ${tabPanel ? 'Verified' : 'Unverified'} Items`} />

  return (
    <DetailView.Panel>
      <Box className="inventory-container unlisted-table">
        <DataGrid
          rows={items || []}
          columns={columns}
          loading={isLoading}
          disableColumnSelector
          disableColumnMenu
          disableDensitySelector
          disableColumnFilter
          components={{Toolbar: SearchToolBar, NoRowsOverlay: NoData}}
          rowCount={total || 0}
          onPageChange={(newPage) => setCurrentPage(newPage)}
          page={currentPage}
          pageSize={filter.take}
          pagination
          paginationMode="server"
          rowsPerPageOptions={[filter.take]}
          onSortModelChange={(newSort) => p?.onSaveChanges({sort: newSort[0]})}
        />
      </Box>
    </DetailView.Panel>
  )
}

export default memo(AuditTable)
