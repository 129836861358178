export const ShopifyScopes = [
  'read_products',
  'write_products',
  'read_orders',
  'write_orders',
  'read_inventory',
  'write_inventory',
  'write_locations',
  'read_locations',
  'write_publications',
  'read_publications',
]
