import React from 'react'
import clx from 'classnames'
import {ToastType} from 'enums'
import {ToastMessage} from 'views_v2/lib/snippets'

const InventoryToast = (p) => {
  const {payload, item, toastType, setToastType} = p
  let header = payload?.title || 'Success'
  let body =
    payload?.content || `The following item has been verified: ${item?.title || ''} (${item?.size || ''}) - ${item?.internalSku || ''}`

  switch (toastType) {
    case ToastType.ERROR: {
      header = payload?.title || 'Failed'
      body = payload?.content || 'Error. Internal SKU was not found.'
      break
    }
    case ToastType.LOADING: {
      header = payload?.title || 'Loading'
      body = payload?.content || 'Please wait while we process the data'
      break
    }
    case ToastType.EXPORTED: {
      body = 'Product exported! Please check your email.'
      break
    }
  }

  if (toastType > ToastType.UNDEFINED) {
    return (
      <div
        className={clx(
          'my-2',
          'rounded',
          'fixed-toast',
          toastType === ToastType.ERROR ? 'bg-danger' : toastType === ToastType.LOADING ? 'bg-loading' : 'bg-success',
        )}
      >
        <ToastMessage {...{header, body}} toggle={() => setToastType(ToastType.UNDEFINED)} />
      </div>
    )
  }

  return null
}

export default InventoryToast
