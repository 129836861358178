import React from 'react'
import {Grid} from '@mui/material'
import CopytCard from 'components/CopytCard'
import {useGlobalStore} from 'provider/global_store/hook'
import {onKeyPress, onlySpaces} from 'views_v2/lib/common'
import {FieldGroup} from 'views_v2/lib/snippets'
import {formatTo, formatDateWithoutTimeZone} from 'util/date_utils'
import {getMinimumFee} from 'util/model'

export const InventoryPricing = ({
  formFields = {},
  hasError,
  errorMessage,
  isConsigned,
  isEnterprise,
  isDesiredReturnReadOnly = false,
  hasPricingData = false,
  readOnly = false,
  onChange = () => null,
  stockXPrice,
  flightClubPrice,
}) => {
  const {user} = useGlobalStore()
  const minFee = getMinimumFee(user)

  const isListPrice = isEnterprise && isConsigned

  return (
    <CopytCard className="consignor-information">
      <CopytCard.Header>
        <CopytCard.Text tag="div">Pricing</CopytCard.Text>
      </CopytCard.Header>
      <CopytCard.Body>
        <Grid container gap={1}>
          <Grid item xs={12} md={5} lg={5.7}>
            <FieldGroup
              {...{readOnly}}
              sLabel="Release Date"
              type={readOnly ? 'text' : 'date'}
              value={formFields?.releaseDate || ''}
              tabIndex="21"
              onChange={(e) => {
                onChange({releaseDate: e.target.value})
              }}
            />
          </Grid>
          <Grid item xs={12} md={5} lg={5.7}>
            <FieldGroup
              {...{readOnly}}
              sLabel="Retail Price"
              value={formFields?.retailPrice || ''}
              onChange={(e) => {
                onChange({retailPrice: e.target.value})
              }}
              placeholder="Enter retail price"
              onKeyPress={(e) => onKeyPress(e, 'num')}
              maxLength={10}
              required
            />
          </Grid>
          <Grid item xs={12} md={5} lg={5.7}>
            <FieldGroup
              {...{readOnly}}
              sLabel="Acquired Date"
              type={readOnly ? 'text' : 'date'}
              value={
                isListPrice
                  ? `${formatTo(formatDateWithoutTimeZone(formFields?.updateAt), 'YYYY-MM-DD')}` || ''
                  : formatTo(formatDateWithoutTimeZone(formFields?.acquiredDate), 'YYYY-MM-DD') || ''
              }
              onChange={(e) => {
                onChange({acquiredDate: e.target.value})
              }}
            />
          </Grid>
          <Grid item xs={12} md={5} lg={5.7}>
            <FieldGroup
              id="numeric"
              disabled={readOnly && isDesiredReturnReadOnly}
              sLabel={isListPrice ? 'List Price' : 'Buy Price'}
              value={isListPrice ? formFields?.desiredReturn : formFields?.price}
              onChange={(e) => {
                const key = isListPrice ? 'desiredReturn' : 'price'
                onChange({[key]: e.target.value})
              }}
              required
              placeholder={`Enter ${isListPrice ? 'list' : 'buy'} price`}
            />
          </Grid>
          {!isEnterprise ? (
            <Grid item xs={12} md={5} lg={5.7}>
              <FieldGroup
                id="numeric"
                disabled={readOnly && isDesiredReturnReadOnly}
                sLabel="List Price *"
                value={formFields?.desiredReturn || ''}
                onChange={(e) => {
                  onChange({desiredReturn: e.target.value})
                }}
                required
                placeholder="Enter list price"
                hasError={hasError && (!formFields.desiredReturn || !onlySpaces(formFields?.desiredReturn))}
                errorMessage={`List Price ${errorMessage}`}
              />
              {/* TODO: Uncomment once payout amount is done */}
              {/* <FieldGroup
              readOnly
              sLabel="Payout Amount"
              value={mw.getPayout(formFields, minFee, isEnterprise) || ''}
            /> */}
            </Grid>
          ) : (
            !isConsigned && (
              <Grid item xs={12} md={5} lg={5.7}>
                <FieldGroup
                  id="numeric"
                  disabled={readOnly && isDesiredReturnReadOnly}
                  sLabel="List Price *"
                  value={formFields?.desiredReturn || ''}
                  onChange={(e) => {
                    onChange({desiredReturn: e.target.value})
                  }}
                  required
                  placeholder="Enter list price"
                  hasError={hasError && (!formFields.desiredReturn || !onlySpaces(formFields?.desiredReturn))}
                  errorMessage={`List Price ${errorMessage}`}
                />
                {/* TODO: Uncomment once payout amount is done */}
                {/* {hasPricingData && isEnterprise && (<FieldGroup
                readOnly
                sLabel="Payout Amount"
                value={mw.getPayout(formFields, minFee, isEnterprise) || ''}
              />)} */}
                {/* </Box> */}
              </Grid>
            )
          )}
          {/* TODO: Uncomment once payout amount is done */}
          {/* {hasPricingData ? (
            <Grid item xs={12} md={5} lg={5.7}>
              <Box className="stockx-price">
                <Box className="stockx-pricing-title">Pricing Data:</Box>
                <Box className="price-data">
                  <Box className="StockX-small-logo"></Box> Lowest Ask:{' '}
                  {stockXPrice ? '$' + stockXPrice : 'No data'}
                </Box>
                <Box className="price-data">
                  <Box className="flightclub-small-logo"></Box> Lowest Ask:{' '}
                  {flightClubPrice ? '$' + flightClubPrice : 'No data'}
                </Box>
              </Box>
            </Grid>
          ) : (
            // <FieldGroup
            //   readOnly
            //   sLabel="Payout Amount"
            //   value={mw.getPayout(formFields, minFee, isEnterprise) || ''}
            // />
          )} */}
        </Grid>
      </CopytCard.Body>
    </CopytCard>
  )
}

export default InventoryPricing
