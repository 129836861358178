import React, {useEffect, useRef, useState} from 'react'
import {Box, Stack, Typography} from '@mui/material'
import {chain, isEmpty, orderBy, chunk, last, unionBy, difference} from 'lodash'
import styled from 'styled-components'

import {categorySizes} from 'assets/data'
import CopytModal from 'components/CopytModal'
import {urlWithoutParam} from 'util/window_utils'
import {CopytButton} from 'views_v2/lib/snippets'
import {ModalContainer, ModalHeading, ModalBody} from 'views_v2/lib/snippets/CopytModal'

import {UnlistedTable, ListedTable, SoldTable, ProcessedTable, InventoryCardList} from './tables'

const CopytBtn = styled(CopytButton)({
  width: '80%',
  height: 35,
  borderRadius: 6,
  textTransform: 'capitalize',
})

const WAIT_INTERVAL = 1000

const _identifyRegion = (size) => {
  if (size.startsWith('UK')) return 'uk'
  else if (size.startsWith('EU')) return 'eu'
  return 'us'
}

const _updatePage = (currentPage) => currentPage % 5

const InventoryBody = (p) => {
  const {chunkIndex, tabPanel, setFilter, showCard, selectedCriteria, filter, setChunkIndex} = p
  const timerRef = useRef(null)
  const [currentPage, setCurrentPage] = useState(filter?.page)
  const [chunks, setChunks] = useState(() => chunk(p.items.data, 100))
  const [isShowModal, setIsShowModal] = useState(false)

  const onPageChange = (newChunkIndex) => {
    if (timerRef?.current) {
      clearTimeout(timerRef?.current)
    }

    timerRef.current = setTimeout(() => {
      if (newChunkIndex >= chunks.length) {
        if (_updatePage(newChunkIndex) === 0) {
          setFilter((f) => ({...f, page: newChunkIndex, skip: Math.floor(newChunkIndex / 5) * f.take}))
        }
      } else {
        setChunkIndex(newChunkIndex)
      }

      const newUrl = urlWithoutParam(newChunkIndex)
      window.history.pushState({path: newUrl}, '', newUrl)
      setCurrentPage(newChunkIndex)
    }, WAIT_INTERVAL)
  }

  useEffect(() => {
    if (p.items.data) {
      setChunks(chunk(p.items.data, 100))
      setChunkIndex(filter?.page >= 5 ? _updatePage(filter?.page) : filter?.page)
    }
  }, [p.items.data])

  useEffect(() => {
    setCurrentPage(currentPage)
    onPageChange(currentPage)
    setChunkIndex(_updatePage(currentPage))
  }, [currentPage])

  useEffect(() => {
    if (currentPage !== filter?.page) {
      setCurrentPage(filter?.page)
      onPageChange(filter?.page)
      setChunkIndex(_updatePage(filter?.page))
    }
  }, [filter?.page])

  useEffect(() => {
    return () => {
      if (timerRef?.current) {
        clearTimeout(timerRef?.current)
      }
    }
  }, [])

  const tableProps = {
    ...p,
    currentPage,
    setCurrentPage,
    isCardList: false,
    allData: p?.items?.data,
    items: {...p.items, data: chunks[chunkIndex] || []},
    onShowModal: setIsShowModal,
  }

  if (showCard) {
    const processItemsWithChain = (items, selectedCriteria) => {
      if (isEmpty(items)) return []

      return chain(items)
        .groupBy((item) => selectedCriteria.map((criteria) => item[criteria]).join('-'))
        .map((group) => {
          const {minBuyPrice, minListPrice} = group.reduce(
            (acc, item) => {
              const price = parseFloat(item.price)
              const retailPrice = parseFloat(item.retailPrice)
              if (!isNaN(price)) acc.minBuyPrice = Math.min(acc.minBuyPrice, price)
              if (!isNaN(retailPrice)) acc.minListPrice = Math.min(acc.minListPrice, retailPrice)
              return acc
            },
            {minBuyPrice: Infinity, minListPrice: Infinity},
          )

          const sortedItems = orderBy(
            group,
            [
              (item) => {
                const {category, size} = item
                let arr =
                  category === 'Accessories' || category === 'Apparel'
                    ? categorySizes.apparel
                    : category?.includes('toddler')
                    ? categorySizes.toddler
                    : category?.includes('kid')
                    ? categorySizes.kid
                    : categorySizes[_identifyRegion(size)] || []
                return arr.indexOf(size) !== -1 ? arr.indexOf(size) : Infinity
              },
            ],
            ['asc'],
          )

          return {
            ...selectedCriteria.reduce((acc, criteria) => ({...acc, [criteria]: group[0][criteria]}), {}),
            BuyPrice: minBuyPrice === Infinity ? null : minBuyPrice,
            ListPrice: minListPrice === Infinity ? null : minListPrice,
            items: sortedItems,
          }
        })
        .value()
    }

    return (
      <InventoryCardList
        {...{selectedCriteria}}
        data={processItemsWithChain(p.items.data, selectedCriteria)}
        page={currentPage}
        onPageChange={(page) => {
          setCurrentPage(page)
          setSIds({})
        }}
        {...tableProps}
      />
    )
  }

  const TableComponent = {
    1: ListedTable,
    2: SoldTable,
    3: ProcessedTable,
  }

  const SelectedTable = TableComponent[tabPanel] || UnlistedTable

  return (
    <>
      <SelectedTable {...tableProps} />
      {(tabPanel === 1 || tabPanel === 0) && (
        <CopytModal className="no-padding" id="confirm-action" show={isShowModal} size="xs" toggle={() => setIsShowModal(!isShowModal)}>
          <ModalContainer>
            <Box className="modal-title">
              <ModalHeading className="modal__heading">
                <Typography variant="h1">Confirm Action</Typography>
              </ModalHeading>
            </Box>
            <ModalBody style={{marginBottom: 40}}>
              <Stack direction="column" alignItems="center" gap={2}>
                <Typography fontWeight="bold" color="var(--default) !important">
                  Please select the action you want to perform:
                </Typography>
                <CopytBtn
                  variant="outline"
                  onClick={() => {
                    p?.onListSelections([])
                    p?.changeCheckbox()
                    setIsShowModal(!isShowModal)
                  }}
                >
                  Remove ALL selected items
                </CopytBtn>
                <CopytBtn
                  variant="outline"
                  onClick={() => {
                    p?.onListSelections(difference(p?.listSelections, tableProps?.items?.data))
                    p?.changeCheckbox()
                    setIsShowModal(!isShowModal)
                  }}
                >
                  Remove SELECTED items from current table
                </CopytBtn>
                <CopytBtn
                  variant="outline"
                  onClick={() => {
                    p?.onListSelections(unionBy(tableProps?.items?.data, p?.listSelections, 'id'))
                    p?.changeCheckbox()
                    setIsShowModal(!isShowModal)
                  }}
                >
                  Add all unselected items from current table
                </CopytBtn>
              </Stack>
            </ModalBody>
          </ModalContainer>
        </CopytModal>
      )}
    </>
  )
}

export default InventoryBody
